import React, { Component } from "react";
import { Container, Row, Col, Card, Form, Button, Modal, ModalBody } from "react-bootstrap";
import QRCode from "react-qr-code";
import "react-html5-camera-photo/build/css/index.css";
import { EnforcementActions, SuperAdminAction } from "../../Actions";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import Resizer from "react-image-file-resizer";
import CustomContentAlert, { validation_message, validations, timestamp, utcToSpecificTimeZone } from "../helper";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import defaultImage from "../../../assets/img/plate-check.png";
import scan from "../../../assets/img/svg/scan.svg";
import ticket from "../../../assets/img/svg/ticket.svg";
import plate_check from "../../../assets/img/svg/plate_check.svg";
import { isMobile } from "react-device-detect";
import audioFileNotAllowed from "../../../assets/sound/NotAllowed.mp3";
import audioFileAllowed from "../../../assets/sound/Allowed.wav";
import GetOfflineData from "./GetOfflineData";
import OfflinePlateCheck from "../../PlateCheck/OfflinePlateCheck";
import moment from "moment";
import OfflineCitation from "./OfflineCitation";
import OfflineCitationList from "./OfflineCitationList";
import db from "./dixie";
import Dexie from "dexie";

class Enforcement extends Component {
  constructor(props) {
    super();

    this.state = {
      properties: [
        { id: "1", name: "property1" },
        { id: "2", name: "property2" },
        { id: "3", name: "property3" },
      ],
      selectedProperty: "",
      image: "",
      // image:
      //   "https://tsrahaman.org/wp-content/uploads/2020/09/blank-user-img.jpg",
      isOpenCamera: false,
      isPlateApproved_: false,
      loader: false,
      extractedPlateNumber_: "",
      lpn: "",
      property: "",
      validPlateNumber: true,
      isFormValid: false,
      isScanEnabled: false,
      show_error: false,
      api_error_message: "",
      domain: "",
      userlots: [],
      resultSound: "",
      playSound: false,
      lotSelectionError: "",
      cameraError: "",
      stalls: [],
      stallDisabled: true,
      showScanningModal: false,
      isQREnabled: false,
      sourceUrl: "",
      trackData: {},
      detectedDevice: "",
      hide: false,
      userOffline: false,
      offline_citation_page: false,
      offline_plate_check: false,
      offline_data: {},
      citationCreateData: {},
      switchDisable: false,
      offline_citation_list: false,
      qrCodeUrl: ``,
    };
  }

  componentDidMount = async () => {
    const detectedDevice = await this.getMobileOperatingSystem();
    this.setState(
      {
        sourceUrl: `${process.env.REACT_APP_URL}`,
        detectedDevice: detectedDevice,
        property: this.props?.property?.property_name,
        domain: this.props?.property?.domain,
        isQREnabled: this.props?.property?.qr_code_scanner === 1,
      },
      () => {
        const selectedPropertyName = this.state.property;
        const selectedDomainName = this.state.domain;
        this.props.setDomainHeader(selectedPropertyName, selectedDomainName);
        console.log(this.state.sourceUrl, "sourceUrl");
      },
    );

    const isItRescan = this.props?.location?.state?.reScan ? this.props?.location?.state?.reScan : "";
    if (isItRescan?.length) {
      this.setState({
        isOpenCamera: true,
        selectedLot: this.props?.location?.state?.selectedLot,
        isScanEnabled: true,
      });
    }
    await this.props.getUserLots();
    if (this.props.api_response) {
      console.log("this.props.user_offline::::::::;", this.props.user_offline);
      this.setState({ userlots: this.props.userlots, userOffline: this.props?.user_offline?.is_offline }, async () => {
        if (this.state.userlots && this.state.userlots.length === 1) {
          const stall = this.state?.userlots[0]?.permits;
          this.setState({
            selectedLot: this.state.userlots[0]?.id,
            isScanEnabled: true,
            stallDisabled: false,
            stalls: stall,
          });
        }
        await this.handleOfflineCitationSession();
      });
    }
  };

  handleOfflineCitationSession = async () => {
    if (this.state.userOffline) {
      if (!db.isOpen()) {
        db.open().catch((error) => {
          console.error("Error on Enforcement handleOfflineCitationSession opening the database:", error);
        });
      }
      const { properties } = db;
      const localProperty = await properties.toArray().then((properties) => properties);
      if (localProperty?.length > 0) {
        this.setState({
          lotSelectionError: "",
          switchDisable: false,
        });
      } else {
        this.setState({
          lotSelectionError: "Lot selection is unavailable as you are currently in offline mode.",
          switchDisable: true,
        });
      }
      this.setState({
        selectedLot: this.props.user_offline?.lot_id,
      });
    } else {
      const databaseNames = await Dexie.getDatabaseNames();
      console.log("databaseNames:::::::::::", databaseNames);
      if (databaseNames.includes("offline_citation")) {
        if (db.isOpen()) {
          db.close();
        }
        await Dexie.delete("offline_citation");
      } else {
        console.log("dixie db not exist");
      }
      this.setState({
        lotSelectionError: "",
      });
    }
  };

  findPermit = async (propertyId, lotId, lpn, curTime) => {
    const { permits } = db;
    const localPermits = await permits.toArray().then((permits) => permits);
    try {
      const matchingPermit = (localPermits ?? []).filter((permit) => {
        return (
          permit.property_id === propertyId &&
          permit.lot_id === lotId &&
          permit.active === 1 &&
          permit.lpn === lpn &&
          permit.begin_time <= curTime &&
          permit.expire_time >= curTime
        );
      });
      console.log("matchingPermit:::::::::::", matchingPermit);
      const firstMatchingPermit = matchingPermit.length > 0 ? matchingPermit[0] : null;
      console.log("firstMatchingPermit:::::::::::", firstMatchingPermit);
      return firstMatchingPermit;
    } catch (error) {
      console.error("Error fetching permit:", error);
      throw error;
    }
  };

  getGraceTime = async (PropertyId) => {
    const { properties } = db;
    const localProperty = await properties.toArray().then((properties) => properties);
    try {
      const property = (localProperty ?? []).filter((property) => property.property_id === PropertyId);
      const firstMatcingProperty = property.length > 0 ? property[0] : null;

      console.log("firstMatcingProperty:::::::::::", firstMatcingProperty);
      return firstMatcingProperty?.grace_time || 0; // Return the grace_time if found, or default to 0
    } catch (error) {
      console.error("Error fetching grace time:", error);
      throw error;
    }
  };

  getAuditData = async (propertyId, lot_id, lpn, curDate, curTime) => {
    const { historic_scanned_plates, scan_plates } = db;

    const scannedPlates = await historic_scanned_plates.toArray().then((scanned_plates) => scanned_plates);
    const scanPlates = await scan_plates.toArray().then((scan_plates) => scan_plates);
    let localScannedPlates = [...scannedPlates, ...scanPlates];
    console.log("localScannedPlates::::::::::", localScannedPlates, curDate, curTime);
    const audiData = (localScannedPlates ?? [])
      .filter((scanned_plate) => {
        return (
          scanned_plate.property_id === propertyId &&
          scanned_plate.lot_id === lot_id &&
          scanned_plate.lpn === lpn &&
          scanned_plate.created_at >= curDate &&
          scanned_plate.created_at <= curTime
        );
      })
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

    console.log("audiData::::::::::", audiData);
    const firstMatchingAuditData = audiData.length > 0 ? audiData[0] : null;

    console.log("firstMatchingAuditData:::::::::::::::", firstMatchingAuditData);
    return firstMatchingAuditData;
  };

  checkPlateOffline = async (lpn, scan_time, selectedLot) => {
    console.log("scan time::::111", lpn, scan_time, selectedLot);
    let grace_time,
      audit_data,
      time_elapsed = 0,
      endDate = "",
      startOfDayUtc;
    let data = {};

    await this.getGraceTime(this.props.PropertyId)
      .then((graceTime) => {
        grace_time = graceTime;
        console.log("Grace Time:", grace_time);
      })
      .catch((error) => {
        console.error("Error on find grace time:", error);
      });

    const curUtcTime = moment.utc();
    const curTime = curUtcTime.format("YYYY-MM-DDTHH:mm:ss.sssZ");

    if (+grace_time <= 1440) {
      startOfDayUtc = curUtcTime.startOf("day");
    } else if (+grace_time > 1440 && +grace_time <= 2880) {
      startOfDayUtc = curUtcTime.subtract(1, "days").startOf("day");
    }

    const curDate = startOfDayUtc.format("YYYY-MM-DDTHH:mm:ss.sssZ");

    const comparisonTime = moment().subtract(grace_time, "minutes").format("YYYY-MM-DD HH:mm:ss");
    console.log("comparisonTime::::::::::::::::::::", comparisonTime);

    if (lpn && selectedLot) {
      await this.getAuditData(this.props.PropertyId, selectedLot, lpn, curDate, curTime)
        .then((auditData) => {
          console.log("audit data::::: Final", auditData);
          audit_data = auditData;
        })
        .catch((error) => {
          console.error("error on find audit data", error);
        });
    }
    const timeToBeCompared = curTime;
    console.log("timeToBeCompared::::::::::::::::::::::", timeToBeCompared);
    console.log("audit_data create_at:::::", audit_data);
    if (grace_time > 0) {
      console.log("audit data:::length::", audit_data);
      if (audit_data) {
        console.log("before time elapsed calles:");
        time_elapsed = moment(curTime).diff(audit_data.created_at, "minutes");
      }
    }

    await this.findPermit(this.props.PropertyId, selectedLot, lpn, curTime)
      .then((permit) => {
        data = permit || {};
        console.log("Matching Permit:", permit);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    console.log("main data::::::::::;", data);

    const full_hours = Math.floor(time_elapsed / 60);
    const hoursPart = full_hours > 0 ? `${full_hours} hours and ` : "";
    const remainingMinutes = time_elapsed % 60;
    const time_elapsed_format = time_elapsed ? `${hoursPart} ${remainingMinutes} minutes` : "0 minutes";

    if (Object.keys(data).length) {
      endDate = data?.expire_time;
      const timezoneRelatedEndDate = utcToSpecificTimeZone(this.props.timezone, endDate, true);
      const date2 = moment(new Date(scan_time));
      const date1 = moment(new Date(timezoneRelatedEndDate));
      const years = date1.diff(date2, "year");
      date2.add(years, "years");

      const months = date1.diff(date2, "months");
      date2.add(months, "months");

      const days = date1.diff(date2, "days");
      date2.add(days, "days");

      const hours = date1.diff(date2, "hours");
      date2.add(hours, "hours");

      const minutes = date1.diff(date2, "minutes");

      date2.add(minutes, "minutes");
      data.time_elapsed = time_elapsed_format;
      data.isPlateApproved = true;
      data.result = "Approved";
      data.source = "";
      const end = endDate ? `${timezoneRelatedEndDate} - ${years} Years, ${months} Months, ${days} days, ${hours} hours, ${minutes} minutes` : "-";
      data.end = end;
    } else {
      console.log("time eeeeee:", time_elapsed, grace_time, grace_time > 0, time_elapsed < grace_time);
      if (grace_time > 0 && time_elapsed < grace_time) {
        data.time_elapsed = time_elapsed_format;
        data.isPlateApproved = true;
        data.result = "Approved";
        data.source = "";
      } else {
        data.time_elapsed = time_elapsed_format;
        data.isPlateApproved = false;
        data.source = "";
        data.result = "Not Approved";
      }
    }

    const scan_data_tobe_passed = {
      type: "scan_plate",
      lpn: lpn,
      created_at: curTime,
      created_by: this.props?.id,
      lot_id: selectedLot,
      property_id: this.props?.PropertyId,
      result: data.result,
    };

    try {
      const scanPlateData = await db.scan_plates.add(scan_data_tobe_passed);
      console.log("scanPlateData", scanPlateData);
    } catch (error) {
      console.error("scan_plate create error", error);
    }

    const _plateData = data;
    const plateData = _plateData && Object.keys(_plateData).length ? _plateData : null;

    this.setState({ plate_data: { ...plateData } });

    console.log("curTime Curr date::::", curTime, curDate, grace_time, comparisonTime, data);
  };

  handlePlateCheck = async () => {
    console.log("handlePlateCheck:::::::: called offline", this.state.userOffline, this.state.lpn);
    if (this.state.userOffline) {
      const { lpn, selectedLot, userlots } = this.state;
      const scan_time = timestamp();

      const selectedLotDetails = userlots?.length ? userlots.filter((item) => item.id === selectedLot)[0] : {};
      const selectedLotName = selectedLotDetails && Object.keys(selectedLotDetails).length ? selectedLotDetails.alias : "";
      const selectedLotCapacity = selectedLotDetails && Object.keys(selectedLotDetails).length ? selectedLotDetails.lot_capacity : "";
      const isVisitorLot = selectedLotDetails && Object.keys(selectedLotDetails).length ? selectedLotDetails.visitor_lot : false;

      const offline_plate_check_data = await this.checkPlateOffline(lpn, scan_time, selectedLot);

      console.log("offline_plate_check_data::::::::", offline_plate_check_data);
      const offline_data_to_bepassed = {
        lpn: lpn,
        main_body: this.props.main_body,
        selectedLotName: selectedLotName,
        selectedLotCapacity: selectedLotCapacity,
        isVisitorLot: isVisitorLot,
      };
      this.setState({
        offline_plate_check: true,
        offline_data: offline_data_to_bepassed,
        offline_citation_page: false,
        offline_citation_list: false,
      });
      console.log("offline plate check enabled::");
    } else {
      this.setState(
        {
          isFormValid: true,
        },
        async () => {
          const { lpn, selectedLot, userlots, image, selectedStall } = this.state;
          await this.props.manuallyPlateCheck({
            lpn,
            selectedLot,
            selectedStall,
          });
          await this.handleOnlinePlateCheckAfterResponse(userlots, image, selectedLot, lpn);
        },
      );
    }
  };

  handleOnlinePlateCheckAfterResponse = async (userlots, image, selectedLot, lpn) => {
    if (this.props.api_response) {
      const { scan_time, tickets, result, email } = await this.OnlinePlateCheckRequiredData();

      const selectedLotDetails = userlots?.length ? (userlots ?? []).filter((item) => item.id === selectedLot)[0] : {};
      const selectedLotName = selectedLotDetails && Object.keys(selectedLotDetails).length ? selectedLotDetails.alias : "";
      const selectedLotCapacity = selectedLotDetails && Object.keys(selectedLotDetails).length ? selectedLotDetails.lot_capacity : "";
      const isVisitorLot = selectedLotDetails && Object.keys(selectedLotDetails).length ? selectedLotDetails.visitor_lot : false;

      const isPlateApproved = this.props.plateCheckData.isPlateApproved;
      const history = require("../../history").default;
      history.push({
        pathname: `/plate-check`,
        state: {
          image: image,
          lpn: lpn,
          isPlateApproved: isPlateApproved,
          scan_time: scan_time,
          email: email,
          data: {
            ...this.props?.plateCheckData?.data,
            time_elapsed: this.props?.plateCheckData?.time_elapsed,
          },
          selectedLot: selectedLot,
          selectedLotName: selectedLotName,
          selectedLotCapacity: selectedLotCapacity,
          isVisitorLot: isVisitorLot,
          tickets: tickets,
          result: result,
          end: this.props?.plateCheckData?.end,
        },
      });
    } else {
      this.setState({
        loader: false,
        show_error: true,
        api_error_message: this.props?.api_error_message,
      });
      setTimeout(() => {
        this.setState({ show_error: false });
      }, 4000);
    }
  };

  OnlinePlateCheckRequiredData = async () => {
    const scan_time = this.props?.plateCheckData?.scan_time ? this.props.plateCheckData.scan_time : "";
    const tickets = this.props?.plateCheckData?.tickets?.length ? this.props.plateCheckData.tickets : [];
    const result =
      this.props?.plateCheckData?.data && Object.keys(this.props.plateCheckData.data).length && this.props.plateCheckData.data?.result
        ? this.props.plateCheckData?.data?.result
        : "";
    const email =
      this.props?.plateCheckData?.data && Object.keys(this.props.plateCheckData.data).length && this.props.plateCheckData.data.email
        ? this.props.plateCheckData.data.email
        : "";
    return { scan_time, tickets, result, email };
  };

  handleTakePhoto = async (imageURL) => {
    const x = this.dataURLtoFile(imageURL);
    const form_data = new FormData();
    form_data.append("lot_id", this.state.selectedLot);
    form_data.append("property_id", this.props.PropertyId);
    console.log("this.state.selectedStall==", this.state.selectedStall);
    let stall_id;
    if (!this.state.selectedStall) {
      stall_id = null;
    } else {
      stall_id = this.state.selectedStall;
    }
    form_data.append("stall_id", stall_id);
    form_data.append("files", x);
    this.setState(
      {
        loader: true,
      },
      async () => {
        await this.props.scanPlate(form_data);
        if (this.props.api_response) {
          this.setState(
            {
              extractedPlateNumber_: this.props.extractedPlateNumber,
              lpn: this.props.extractedPlateNumber,
              loader: false,
              isOpenCamera: false,
              image: imageURL,
            },
            async () => {
              if (this.props.api_response) {
                const history = require("../../history").default;

                const { image, selectedLot, userlots } = this.state;
                const scan_time = this.props?.plateCheckData?.scan_time ? this.props.plateCheckData.scan_time : "";
                const tickets = this.props?.plateCheckData?.tickets && this.props.plateCheckData.tickets.length ? this.props.plateCheckData.tickets : [];

                const result = this.props.plateCheckData?.data?.result || "";
                const email = this.props.plateCheckData?.data?.email || "";

                const selectedLotDetails = userlots?.length ? userlots.filter((item) => item.id === selectedLot)[0] : {};
                const selectedLotName = selectedLotDetails && Object.keys(selectedLotDetails).length ? selectedLotDetails.alias : "";
                const selectedLotCapacity = selectedLotDetails && Object.keys(selectedLotDetails).length ? selectedLotDetails.lot_capacity : "";
                const isVisitorLot = selectedLotDetails && Object.keys(selectedLotDetails).length ? selectedLotDetails.visitor_lot : false;

                const isPlateApproved = this.props.plateCheckData.isPlateApproved;

                history.push({
                  pathname: `/plate-check`,
                  state: {
                    image: image,
                    lpn: this.props?.plateCheckData?.lpn || this.props.plateCheckData?.data?.lpn,
                    isPlateApproved: isPlateApproved,
                    scan_time: scan_time,
                    email: email,
                    data: this.props.plateCheckData.data,
                    selectedLot: selectedLot,
                    selectedLotName: selectedLotName,
                    selectedLotCapacity: selectedLotCapacity,
                    isVisitorLot: isVisitorLot,
                    tickets: tickets,
                    result: result,
                    end: this.props.plateCheckData.end,
                  },
                });
                this.setState({
                  verified: true,
                });
              } else {
                this.setState({
                  verified: false,
                  playSound: true, // need to rollback to true after pushing the code
                });
              }
              if (this.state.lpn) {
                this.setState({
                  isFormValid: true,
                  loader: false,
                });
              } else {
                this.setState({
                  isFormValid: false,
                  loader: false,
                });
              }
            },
          );
        } else {
          this.setState({
            loader: false,
            isOpenCamera: false,
            show_error: true,
            api_error_message: "Scan failed, please scan again or enter manually",
            verified: false,
            playSound: true,
          });
          setTimeout(() => {
            this.setState({ show_error: false, playSound: false });
          }, 4000);
        }
      },
    );
    if (this.state.detectedDevice !== "iOS") {
      console.log("this.state.tractData", this.state.trackData);
      if (this.state.trackData?.kind) {
        try {
          this.state.trackData
            .applyConstraints({
              advanced: [{ torch: false }],
            })
            .then(() => {
              console.log("Tourch disabled.");
            })
            .catch((error) => {
              if (error.name === "OverconstrainedError") {
                console.warn("The constraint cannot be satisfied by the device: ", error);
              } else {
                console.error("Error applying constraints: ", error);
              }
            });
        } catch (error) {
          console.error("Error applying constraints: ", error);
        }
      }
    }
  };

  dataURLtoFile = (dataurl) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "lpn.png", { type: mime });
  };

  resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1300,
        1300,
        "PNG",
        75,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64",
      );
    });

  handleChange = (e) => {
    const lpnSpaceRemove = e.target.value.replace(/\s/g, "");
    this.setState({ lpn: lpnSpaceRemove.toUpperCase().replace(/[^\w\s]/gi, "") }, async () => {
      const { lpn, selectedLot } = this.state;
      const plateNumber = validations(e.target.name, "lpn", lpn);
      this.setState({
        lpn: plateNumber.value,
        validPlateNumber: plateNumber.validator,
      });
      if (lpn && selectedLot) {
        this.setState({
          isFormValid: true,
        });
      } else {
        this.setState({
          isFormValid: false,
        });
      }
    });
  };

  changeProperty = (event) => {
    console.log("changes ppppp:===", event.target.value);
    this.setState(
      {
        selectedLot: event.target.value,
      },
      () => {
        const { lpn, selectedLot, userlots } = this.state;
        if (selectedLot) {
          const stall = (userlots ?? []).filter((item) => item.id === this.state.selectedLot);
          this.setState({
            stallDisabled: false,
            isScanEnabled: true,
            lotSelectionError: "",
            stalls: stall[0]?.permits,
          });
        } else {
          this.setState({
            lotSelectionError: "Please select lot first",
            isScanEnabled: false,
            isOpenCamera: false,
            stallDisabled: true,
          });
        }
        if (lpn && selectedLot) {
          this.setState({
            isFormValid: true,
            lotSelectionError: "",
          });
        } else {
          this.setState({
            isFormValid: false,
          });
        }
      },
    );
  };

  changeStall = (event) => {
    this.setState({
      selectedStall: event.target.value,
    });
  };

  redirectToTicket = () => {
    const history = require("../../history").default;
    history.push({
      pathname: "/citation",
      state: {
        image: "",
      },
    });
    this.setState({
      validPlateNumber: true,
    });
  };

  redirectOfflineCitation = () => {
    if (this.state.offline_plate_check) {
      this.setState({
        offline_plate_check: false,
        lpn: "",
        isFormValid: false,
      });
    }

    this.setState({
      offline_citation_page: !this.state.offline_citation_page,
      offline_citation_list: false,
      validPlateNumber: true,
    });
  };

  platePageHideShow = () => {
    this.setState({
      offline_plate_check: false,
      offline_citation_list: false,
      lpn: "",
      isFormValid: false,
    });
  };

  handleCameraError = (error) => {
    console.log("error", error);
    this.setState({
      cameraError: "Permission Denied",
    });
  };

  openQrModal = () => {
    const remote_url = `${this.state.sourceUrl}/photo-verify-remote?property_id=${this.props.PropertyId}&&lot_id=${this.state.selectedLot}`;
    this.setState(
      {
        qrCodeUrl: remote_url,
      },
      () => {
        this.setState({
          showScanningModal: true,
        });
      },
    );
  };

  closeQrModal = () => {
    this.setState({
      showScanningModal: false,
    });
  };

  handleHideScanningMode = (value) => {
    this.setState({ hide: value, userOffline: value, offline_plate_check: false, selectedStall: "" });
    if (!value) {
      this.setState({
        offline_citation_page: false,
        offline_citation_list: false,
        switchDisable: false,
        lotSelectionError: "",
        lpn: "",
      });
      const { selectedLot, userlots } = this.state;
      if (selectedLot) {
        const stall = (userlots ?? []).filter((item) => item.id === this.state.selectedLot);
        this.setState({
          stallDisabled: false,
          isScanEnabled: true,
          lotSelectionError: "",
          stalls: stall[0]?.permits,
        });
      }
    }
  };

  getMobileOperatingSystem = async () => {
    const userAgent = navigator.userAgent || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

  startFlash = (stream) => {
    if (this.state.detectedDevice !== "iOS") {
      const track = stream.getVideoTracks()[0];
      this.setState({ trackData: track });

      // Create image capture object and get camera capabilities
      if ("ImageCapture" in window) {
        const imageCapture = new ImageCapture(track);
        imageCapture.getPhotoCapabilities().then(() => {
          try {
            track
              .applyConstraints({
                advanced: [{ torch: true }],
              })
              .then(() => {
                console.log("Torch enabled");
              })
              .catch((error) => {
                if (error.name === "OverconstrainedError") {
                  console.warn("The constraint cannot be satisfied by the device: ", error);
                } else {
                  console.error("Error applying constraints: ", error);
                }
              });
          } catch (error) {
            console.error("Error applying constraints: ", error);
          }
        });
      } else {
        console.log("ImageCapture is not supported in this browser.");
      }
    }
  };

  GetcitationData = async (value) => {
    this.setState({
      offline_citation_page: value,
      offline_citation_list: true,
    });
  };

  handleOfflineCitationList = async () => {
    console.log("offline citation list called:::");
    this.setState({
      offline_citation_list: true,
      offline_plate_check: false,
      offline_citation_page: false,
      validPlateNumber: true,
    });
  };

  handleSessionEnd = async () => {
    this.setState({
      switchDisable: true,
      lpn: "",
    });
  };

  render() {
    const {
      image,
      lpn,
      isOpenCamera,
      loader,
      validPlateNumber,
      isFormValid,
      show_error,
      api_error_message,
      verified,
      playSound,
      isScanEnabled,
      stallDisabled,
      isQREnabled,
      hide,
      userOffline,
      offline_plate_check,
      offline_data,
      plate_data,
      //  citationCreateData,
      lotSelectionError,
      switchDisable,
    } = this.state;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col>
              <Card className="scanner-card">
                <Card.Header style={{ backgroundColor: this.props.main_body }}>
                  <Row>
                    <Col xl="6" lg="6" md="6" sm="6" xs="12" className="d-flex align-items-center mt-2">
                      <h4 className="card-title">Verify Audit</h4>
                      <div style={{ marginLeft: 20 }}>
                        <Form.Control
                          as="select"
                          name="selectedLot"
                          placeholder="lot"
                          value={this.state.selectedLot}
                          className="photo-verify-form-control"
                          onChange={this.changeProperty}
                          disabled={userOffline ? true : false}
                        >
                          <option value="">--Choose Lot--</option>
                          {(this.state.userlots ?? []).map((e) => {
                            return (
                              <option key={e.id} value={e.id}>
                                {e.alias}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <span className={"help-block"}>{this.state.lotSelectionError}</span>
                      </div>
                    </Col>
                    <Col xl="6" lg="6" md="6" sm="6" xs="12">
                      {" "}
                      {this.state.selectedLot && (
                        <GetOfflineData
                          timeZone={this.props.timezone}
                          CreateOrUpdateOfflineUser={this.props.CreateOrUpdateOfflineUser}
                          selectedLot={this.state.selectedLot}
                          PropertyId={this.props.PropertyId}
                          changeState={this.handleHideScanningMode}
                          //  dataInsertInLocalDb={citationCreateData}
                          lotSelectionError={lotSelectionError}
                          handleSessionEnd={this.handleSessionEnd}
                        />
                      )}
                    </Col>
                    {!hide && (
                      <Col xl="7" lg="7" md="7" sm="7" xs="12" className="d-flex align-items-center mt-2">
                        <h4 className="card-title"> Stall </h4>
                        <div style={{ marginLeft: 20 }}>
                          <Form.Control
                            as="select"
                            name="selectedStall"
                            placeholder="stall"
                            value={this.state.selectedStall}
                            className="photo-verify-form-control"
                            onChange={this.changeStall}
                            disabled={stallDisabled}
                          >
                            <option value="">--All--</option>
                            {(this.state.stalls ?? []).map((e, key) => {
                              return (
                                e.stall_id !== null &&
                                e.stall_id && (
                                  <option key={key} value={e.stall_id}>
                                    {e.stall_id}
                                  </option>
                                )
                              );
                            })}
                          </Form.Control>
                          <span className={"help-block"}></span>
                        </div>
                        {isQREnabled && !hide && (
                          <div>
                            <Button onClick={() => this.openQrModal()} disabled={!isScanEnabled} className="ml-2">
                              Create QR Code
                            </Button>
                          </div>
                        )}
                      </Col>
                    )}
                  </Row>
                </Card.Header>
                <Card.Body>
                  {playSound && (
                    <div>
                      <audio id="audio" style={{ display: "none" }} controls autoPlay={true} preload>
                        <source src={verified ? audioFileAllowed : audioFileNotAllowed}></source>
                      </audio>
                    </div>
                  )}

                  <Row>
                    <Col lg="12" className="pr-md-1 center scanned-image">
                      {isOpenCamera && !hide && (
                        <div>
                          {!loader && (
                            <>
                              {this.state.cameraError === "" ? (
                                <div class="position-relative d-inline-block">
                                  <Button
                                    className="position-absolute btn btn-danger btn-sm"
                                    style={{ top: "0", right: "0", zIndex: 5 }}
                                    variant="danger"
                                    onClick={() =>
                                      this.setState({
                                        isOpenCamera: !isOpenCamera,
                                      })
                                    }
                                  >
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                  </Button>
                                  <Camera
                                    onTakePhoto={(dataUri) => {
                                      this.handleTakePhoto(dataUri);
                                    }}
                                    onCameraStart={(stream) => this.startFlash(stream)}
                                    // isImageMirror={true}
                                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                                    isImageMirror={false}
                                    onCameraError={(error) => {
                                      this.handleCameraError(error);
                                    }}
                                    isDisplayStartCameraError={true}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    marginTop: "60px",
                                    marginBottom: "60px",
                                  }}
                                >
                                  <span>{this.state.cameraError}</span>
                                </div>
                              )}
                            </>
                          )}
                          {loader && (
                            <div className="loader">
                              <Loader />
                            </div>
                          )}
                        </div>
                      )}

                      <>{!isOpenCamera && !hide && <img src={image ? this.state.image : defaultImage} alt="" className="image-preview" accept="image/*" />}</>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="5" md="6" sm="12" xs="12" className="d-flex">
                      {!hide && (
                        <Button onClick={() => this.setState({ isOpenCamera: true })} className="text-nowrap mr-2" disabled={!isScanEnabled}>
                          <img src={scan} width="18" />
                          {!isMobile && <span className="pl-2">Scan Plate</span>}
                        </Button>
                      )}
                      <Button className="mr-2" onClick={userOffline ? this.redirectOfflineCitation : this.redirectToTicket} disabled={switchDisable}>
                        <img src={ticket} width="18" />
                        {!isMobile && <span className="pl-2">Citation</span>}
                      </Button>
                      {"    "}
                      {userOffline && (
                        <div className="ml-3">
                          <Button className="mr-2" onClick={this.handleOfflineCitationList} disabled={switchDisable}>
                            <img src={ticket} width="18" />
                            {!isMobile && <span className="pl-2">Citation List</span>}
                          </Button>
                        </div>
                      )}
                    </Col>
                    <Col lg="6" md="6" sm="12" xs="12" className="mt-3 mt-md-0">
                      <div className="d-flex align-items-start">
                        <Button type="button" className="text-nowrap mr-2" onClick={this.handlePlateCheck} disabled={!isFormValid || switchDisable}>
                          <img src={plate_check} width="18" />
                          {!isMobile && <span className="pl-2">Plate Check</span>}
                        </Button>
                        <div>
                          <Form.Control
                            placeholder="Enter plate number"
                            type="text"
                            name="lpn"
                            value={lpn}
                            autoComplete="off"
                            className="photo-verify-form-control"
                            onChange={(e) => this.handleChange(e)}
                            disabled={switchDisable}
                          />
                          {validation_message(lpn, "Plate number", validPlateNumber)}
                        </div>
                      </div>
                    </Col>

                    {/*   <Col lg="3" className="pr-md-1 center responsive-mb-3">
                      <Button
                        type="button"
                        className="check-plate-input"
                        onClick={this.handlePlateCheck}
                        disabled={!isFormValid}
                      >
                        <img src={plate_check} width="18" />
                        {!isMobile && <span className="pl-2">Plate Check</span>}
                      </Button>
                    </Col> */}
                    {/*   <Col lg="3" className="pr-md-1 center responsive-mb-3">
                      <Button>
                        <img src={ticket} width="18" />
                        {!isMobile && <span className="pl-2">Ticket</span>}
                      </Button>
                    </Col> */}
                  </Row>
                </Card.Body>
              </Card>
              {show_error && <CustomContentAlert delay={4000} message={api_error_message} className="toast-error" />}
            </Col>
          </Row>
          <Row>
            {this.state.offline_citation_page ? (
              <OfflineCitation redirectOfflineCitation={this.redirectOfflineCitation} GetcitationData={this.GetcitationData} />
            ) : (
              this.state.offline_citation_list && <OfflineCitationList />
            )}
          </Row>
          <Row>{offline_plate_check && <OfflinePlateCheck offlineData={offline_data} plateData={plate_data} platePageHideShow={this.platePageHideShow} />}</Row>
          <Modal
            lg={"small"}
            show={this.state.showScanningModal}
            backdrop={"static"}
            dialogClassName="modal-90w"
            fullscreen={"sm-down"}
            className="scanner-modal"
          >
            <Card.Header className="d-flex justify-content-between bg-white align-items-center">
              <h4 className="m-0 font-weight-bold">
                <Card.Title className="m-0">Scan QR code</Card.Title>
              </h4>
              <Button title={"Deactivate"} type="button" onClick={() => this.closeQrModal()} className="close-btn py-0" data-dismiss="modal">
                <i className="nc-icon nc-simple-remove text-danger" />
              </Button>
            </Card.Header>
            <ModalBody className="d-flex justify-content-center">
              <QRCode value={this.state?.qrCodeUrl} />
            </ModalBody>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, APIResponseDetails, EnforcementDetails, Logindetails }) => {
  const { api_response, api_error_message, api_success_message } = APIResponseDetails;
  const { property_id: PropertyId, timezone, property, id } = UserProfileDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  const { plateCheckData, extractedPlateNumber, userlots, user_offline } = EnforcementDetails;
  return {
    api_response,
    api_error_message,
    api_success_message,
    PropertyId,
    timezone,
    property,
    plateCheckData,
    extractedPlateNumber,
    userlots,
    user_offline,
    main_body,
    id,
  };
};

const mapStateToDispatch = {
  scanPlate: EnforcementActions.scanPlate,
  manuallyPlateCheck: EnforcementActions.manuallyPlateCheck,
  setDomainHeader: SuperAdminAction.setDomainHeader,
  getUserLots: EnforcementActions.get_user_lots,
  CreateOrUpdateOfflineUser: EnforcementActions.CreateOrUpdateOfflineUser,
};

export default connect(mapStateToProps, mapStateToDispatch)(Enforcement);

import React, { useState } from "react";
import { saveAs } from "file-saver";
import DataTable from "react-data-table-component";
import { Row, Col, Card, Button, Tooltip, OverlayTrigger, Form } from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import * as SuperAdminAction from "../../Actions/SuperAdminAction/actions";
import * as ReportsAction from "../../Actions/ReportsAction/action";
import PropertDetails from "../LandingPage/PropertySelection";
import DatatableHeader from "../DatatableHeader/DatatableHeader";
import Loader from "../Loader/Loader";
import { tableHeightScrollBars, findBySearch, scroolToTop, utcToSpecificTimeZone } from "../helper";
import t from "../../Actions/ReportsAction/types";
import { findStartDateAndEndDateForAllReports } from "../../Actions/common";
import DateRangeAndAmount from "../DateRangeAndAmount/DateRangeAndAmount";

const MeterBattery = () => {
  const today = new Date();
  const datebeforeThirtyDays = today.setDate(today.getDate() - 3);
  const dispatch = useDispatch();
  const batteryList = useSelector((state) => state.ReportsDetails.batteryList);
  const agentData = useSelector((state) => state.ReportsDetails.agentIdList);
  const exportList = useSelector((exportState) => exportState.ReportsDetails.batteryExportList);
  const UserData = useSelector((userState) => userState.UserProfileDetails);
  const selectAllProperty = useSelector((propertyState) => propertyState.SuperAdminDetails.getAllProperties);
  const loader = useSelector((loader) => loader.ReportsDetails.loader);

  const [state, setState] = useState({
    selectedProperty: UserData.property.id,
    selectedPropertyObj: {},
    childcheckbox: true,
    startDate: new Date(datebeforeThirtyDays),
    endDate: new Date(),
    currentSummaryDate: null,
    timezone: UserData.timezone,
    exportValue: false,
    selectedDomainName: "",
    batteryListData: [],
    exportButtonDisable: true,
    finalTableHeight: 0,
    searchValue: "",
    batteryListCount: 0,
    agent_id: "",
    maxEndDate: new Date(),
    startDateTime: "",
    endDateTime: "",
  });

  const [apiCalled, setApiCalled] = useState(false);
  const [agentIdList, setAgentIdList] = useState([]);

  React.useEffect(() => {
    const height = tableHeightScrollBars();
    dispatch(SuperAdminAction.get_all_properties());
    dispatch(ReportsAction.getAgentIdData(state.selectedProperty));
    setState((prev) => ({
      ...prev,
      finalTableHeight: height,
    }));
  }, []);

  React.useEffect(() => {
    setAgentIdList(agentData);
  }, [agentData]);
  console.log("agentData::::::::::", agentData);

  React.useEffect(() => {
    if (state.searchedBatteryList) {
      setState((prev) => ({
        ...prev,
        batteryListCount: state.searchedBatteryList.length,
      }));
    }
  }, [state.searchedBatteryList]);

  React.useEffect(() => {
    const selectedProperty = selectAllProperty.length && selectAllProperty.find((item) => item.id === state.selectedProperty);
    const selectedPropertyName = UserData.property?.property_name;
    const selectedDomainName = UserData.property?.domain;
    if (selectedProperty) {
      setState((prev) => ({
        ...prev,
        selectedPropertyObj: {
          label: selectedProperty.site_name,
          value: selectedProperty.id,
        },
        selectedDomainName: selectedDomainName,
      }));
    }
    dispatch(SuperAdminAction.setDomainHeader(selectedPropertyName, selectedDomainName));
  }, [selectAllProperty]);

  React.useEffect(() => {
    const body = {
      property_id: state.selectedProperty,
      childcheckbox: state.childcheckbox,
      timezone: state.timezone,
      exportValue: state.exportValue,
      startDate: state.startDate,
      endDate: state.endDate,
      currentSummaryDate: state.currentSummaryDate,
    };
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: true,
    });
    dispatch(ReportsAction.meterBatteryList(body));
    GetDateRangeWithFormat(body);
    setApiCalled(true);
  }, []);

  React.useEffect(() => {
    if (batteryList && apiCalled === true) {
      setState((prev) => ({
        ...prev,
        batteryListData: batteryList.rows,
        batteryListCount: batteryList.count,
      }));
    }
  }, [batteryList, apiCalled]);

  React.useEffect(() => {
    const currentTimeDate = moment().format("YYYYMMDD-hhmmss");
    const exportDataName = `METER_BATTERY-${currentTimeDate}-${state.selectedDomainName}.xls`;
    if (apiCalled === true) {
      setApiCalled(false);
      saveAs(
        new Blob([exportList], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        exportDataName,
      );
    }
  }, [exportList]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const showAgentId = (row) => (
    <>
      {row?.agent_id ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.agent_id}</Tooltip>}>
          <div className="text-nowrap text-truncate pr-2">{row?.agent_id}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const showDomainField = (row) => (
    <>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{row?.domain || "-"}</Tooltip>}>
        <div className="text-nowrap text-truncate pr-2">{row?.domain || "-"}</div>
      </OverlayTrigger>
    </>
  );

  const showBatteryField = (name) => {
    return (
      <>
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{name}</Tooltip>}>
          <div className="text-nowrap text-truncate">{name}</div>
        </OverlayTrigger>
      </>
    );
  };

  const showReportedTimeField = (row) => (
    <>
      {row.reported_time ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement={"top"}
          overlay={<Tooltip id={`tooltip-qw`}>{utcToSpecificTimeZone(state.timezone, row.reported_time, true)}</Tooltip>}
        >
          <div className="text-nowrap text-truncate pr-2">{utcToSpecificTimeZone(state.timezone, row.reported_time, true)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      )}
    </>
  );

  const columns = [
    {
      name: "Domain",
      width: "120px",
      sortable: true,
      cell: (row) => showDomainField(row),
      selector: (row) => row?.domain,
    },
    {
      name: "Agent Id",
      width: "150px",
      sortable: true,
      cell: (row) => showAgentId(row),
      selector: (row) => row.agent_id,
    },
    {
      name: "Reported Time",
      sortable: true,
      width: "160px",
      cell: (row) => showReportedTimeField(row),
      selector: (row) => row?.reported_time,
    },
    {
      name: "Battery Voltage",
      width: "200px",
      sortable: true,
      cell: (row) => showBatteryField(row.battery_voltage),
      selector: (row) => row.battery_voltage,
    },
    {
      name: "Battery State",
      width: "200px",
      sortable: true,
      cell: (row) => showBatteryField(row.battery_state),
      selector: (row) => row.bettery_state,
    },
    {
      name: "Battery Temp",
      width: "200px",
      sortable: true,
      cell: (row) => showBatteryField(row.battery_temp),
      selector: (row) => row.battery_temp,
    },
    {
      name: "Battery Power",
      width: "200px",
      sortable: true,
      cell: (row) => showBatteryField(row.battery_power),
      selector: (row) => row.battery_power,
    },
    {
      name: "Battery Charger",
      width: "200px",
      sortable: true,
      cell: (row) => showBatteryField(row.battery_charger),
      selector: (row) => row.battery_charger,
    },
  ];

  const dataTableHandle = (data) => {
    return (
      <DataTable
        data={state.searchValue?.length ? state.searchedBatteryList : data}
        columns={columns}
        customStyles={customStyles}
        responsive={true}
        defaultSortAsc={true}
        fixedHeader={true}
        onChangePage={scroolToTop}
        fixedHeaderScrollHeight={state.finalTableHeight}
        pagination={true}
        paginationPerPage={100}
        paginationTotalRows={state.batteryListCount}
        paginationRowsPerPageOptions={[100, 200, 300, 400]}
      />
    );
  };

  const handleSearchChange = async ({ target }) => {
    const val = target?.value?.toString().trim();

    setState((prev) => ({ ...prev, searchValue: val }));
    const columnList = ["battery_voltage", "agent_id", "reported_time", "battery_state", "battery_temp", "battery_power", "battery_charger"];
    if (val?.length) {
      if (!state.findMore) {
        setState((prev) => ({
          ...prev,
          searchedBatteryList: findBySearch(val, state.batteryListData, state.batteryListData, columnList, state.timezone),
        }));
      }
    } else {
      setState((prev) => ({
        ...prev,
        count: batteryList.count,
        searchedBatteryList: [],
        batteryListData: batteryList.rows,
      }));
    }
  };

  const handleExport = async () => {
    setState({ ...state, exportValue: true });
    const { selectedProperty, childcheckbox, timezone, currentSummaryDate, startDate, endDate, agent_id } = state;
    const pageobj = {
      childcheckbox,
      timezone,
      exportValue: true,
    };
    if (Object.keys(state.selectedPropertyObj).length) {
      pageobj.property_id = state.selectedPropertyObj.value;
    } else {
      pageobj.property_id = selectedProperty;
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    } else if (startDate && endDate) {
      pageobj.startDate = startDate;
      pageobj.endDate = endDate;
    }
    if (agent_id) {
      pageobj.agent_id = agent_id;
    }
    setApiCalled(true);
    dispatch(ReportsAction.meterBatteryList(pageobj));
    GetDateRangeWithFormat(pageobj);
  };

  const resetAll = async () => {
    setState((prev) => ({
      ...prev,
      selectedProperty: UserData.property.id,
      currentSummaryDate: null,
      startDate: new Date(datebeforeThirtyDays),
      endDate: new Date(),
      selectedPropertyObj: {
        label: UserData.property.property_name,
        value: UserData.property.id,
      },
      childcheckbox: true,
      agent_id: "",
      maxEndDate: new Date(),
    }));
  };

  const handleSearch = () => {
    setState((prev) => ({ ...prev, loader: true }));
    const { childcheckbox, selectedPropertyObj, currentSummaryDate, startDate, endDate, selectedProperty, timezone, agent_id } = state;
    const pageobj = {
      childcheckbox,
      timezone,
    };
    if (Object.keys(selectedPropertyObj).length) {
      pageobj.property_id = selectedPropertyObj.value;
    } else {
      pageobj.property_id = selectedProperty;
    }
    if (currentSummaryDate) {
      pageobj.currentSummaryDate = currentSummaryDate;
    } else if (startDate && endDate) {
      pageobj.startDate = startDate;
      pageobj.endDate = endDate;
    }
    if (agent_id) {
      pageobj.agent_id = agent_id;
    }
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: true,
    });
    dispatch(ReportsAction.meterBatteryList(pageobj));
    GetDateRangeWithFormat(pageobj);
    document.body.classList.remove("foc-open");
  };

  const hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  const handleCheckBox = () => {
    setState((prev) => ({
      ...prev,
      childcheckbox: !state.childcheckbox,
    }));
  };

  const changeProperty = (event) => {
    setState((prev) => ({
      ...prev,
      selectedProperty: event.value,
      selectedPropertyObj: event,
    }));
  };

  const handleCurrentDate = (date) => {
    setState((prev) => ({
      ...prev,
      currentSummaryDate: date,
      startDate: null,
      endDate: null,
    }));
  };

  const handleChangeStartingDates = (date) => {
    const dt = new Date(date);
    const getEndDate = new Date(dt.setMonth(dt.getMonth() + 1));
    let selectEndDate;
    if (getEndDate > new Date()) {
      selectEndDate = new Date();
    } else {
      selectEndDate = getEndDate;
    }
    setState((prev) => ({
      ...prev,
      currentSummaryDate: null,
      startDate: date,
      endDate: selectEndDate,
      maxEndDate: selectEndDate,
    }));
  };

  const handleChangeEndDates = (date) => {
    setState((prev) => ({
      ...prev,
      currentSummaryDate: null,
      endDate: date,
    }));
  };

  const handleFilterChange = React.useCallback((e) => {
    setState((prev) => ({
      ...prev,
      agent_id: e.target.value,
    }));
  });

  const GetDateRangeWithFormat = async (pageobj) => {
    const { start_date_without_utc, end_date_without_utc } = await findStartDateAndEndDateForAllReports(pageobj);
    console.log("object:::::::::::::::::::::", pageobj, start_date_without_utc, end_date_without_utc);
    setState(() => ({
      ...state,
      startDateTime: start_date_without_utc,
      endDateTime: end_date_without_utc,
    }));
  };

  return (
    <div className="container-fluid">
      <Row>
        <Col>
          <Card className="mb-0">
            <Card.Body className="pos-rel-overflow-hide">
              <>
                <div className="sidebarFilter">
                  <Card className="contact-container mb-0">
                    <Card.Header // style={{ backgroundColor: this.props.main_body }}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <Card.Title as="h4">Meter Battery Report</Card.Title>
                        <a
                          onClick={hideSlider}
                          href="javascript:void(0);"
                          className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </a>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col md="12">
                          <div className="mb-2">
                            <label className="fancy-checkbox">
                              <input
                                type="checkbox"
                                name={"childcheckbox"}
                                checked={state.childcheckbox}
                                onChange={handleCheckBox}
                                className="custom-control-input"
                                id="childcheckbox1"
                              ></input>
                              <span>
                                <i></i>Include Children Sites
                              </span>
                            </label>
                          </div>
                        </Col>
                        <Col md="12">
                          <PropertDetails selectedPropertyObj={state.selectedPropertyObj} changeProperty={changeProperty.bind(this)} />
                        </Col>
                        <Col md="12">
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Agent Id</Form.Label>
                            <Form.Control as="select" name="agent_id" value={state.agent_id} onChange={handleFilterChange} placeholder="Select Agent Id">
                              <option value="ALL">ALL</option>
                              {(agentIdList ?? []).map((i) => (
                                <option key={i.id}>{i?.server_id}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group>
                            <label className="mb-0 mr-2">Specific Date:</label>
                            <DatePicker
                              className="form-control"
                              placeholderText="Select Date"
                              selected={state.currentSummaryDate}
                              value={state.currentSummaryDate}
                              //  disabled={state.loader}
                              selectsStart
                              timeInputLabel="Time:"
                              onChange={handleCurrentDate}
                              dateFormat="MM/dd/yyyy"
                            />
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group>
                            <label className="mb-0 mr-2">Start Date(Date Range):</label>
                            <DatePicker
                              className="form-control"
                              placeholderText="Select Start Date"
                              selected={state.startDate}
                              defaultHour={24}
                              value={state.startDate}
                              //  disabled={state.loader}
                              selectsStart
                              timeInputLabel="Time:"
                              onChange={handleChangeStartingDates}
                              dateFormat="MM/dd/yyyy"
                              startDate={state.startDate}
                              endDate={state.endDate}
                              maxDate={new Date()}
                            />
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group>
                            <label className="mb-0 mr-2"> End Date(Date Range):</label>
                            <DatePicker
                              className="form-control"
                              placeholderText="Select End Date"
                              selected={state.endDate}
                              //  disabled={state.loader}
                              value={state.endDate}
                              selectsEnd
                              timeInputLabel="Time:"
                              onChange={handleChangeEndDates}
                              // dateFormat="MM/dd/yyyy hh:mm:ss aa"
                              dateFormat="MM/dd/yyyy"
                              // showTimeInput
                              startDate={state.startDate}
                              endDate={state.endDate}
                              minDate={state.startDate}
                              maxDate={state.maxEndDate}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer className="text-right justify-content-between d-flex">
                      <Button variant="info" className="h-40 custom-btn-secondary btn-sm" onClick={resetAll} disabled={loader}>
                        Reset
                      </Button>
                      <Button variant="info" className="h-40 btn-fill btn-sm ml-2" onClick={handleSearch} disabled={loader}>
                        Search
                      </Button>
                    </Card.Footer>
                  </Card>
                </div>
                <header>
                  <div className="active-permit-header sm-flex-column sm-align-items-start">
                    <h5 className="mb-2 mb-md-0">Meter Battery Report</h5>
                    <div className="filter-button d-flex flex-wrap search-filter-hide">
                      <div className="d-flex align-items-center search-mb-0">
                        <DatatableHeader className="mt-0 mb-0" searchValue={state.searchValue} HandleChange={handleSearchChange} />
                      </div>
                      <div className="ml-2">
                        <Button
                          variant="info"
                          className="btn-fill btn-sm"
                          onClick={handleExport}
                          disabled={state.batteryListData?.length > 0 ? 0 : 1}
                          size="medium"
                        >
                          Export
                        </Button>
                      </div>
                    </div>
                  </div>
                </header>
              </>
              {loader ? (
                <Loader />
              ) : (
                <>
                  {state.batteryListData && <div className="citation-list-table">{dataTableHandle(state.batteryListData)}</div>}
                  <DateRangeAndAmount startDateTime={state.startDateTime} endDateTime={state.endDateTime} />
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MeterBattery;

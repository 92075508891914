import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, Form, Container, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import {
  getAllPermitOptions,
  addPermitsByUnit,
  findUnitSettings,
  getCardDetails,
  getAllUnitPermitList,
  getAllPermitGroupList,
  DeleteCreditCardDetails,
  getAllUnitStall,
} from "../../Actions/PermitOptionsActions/actions";
import { fetchResidentList } from "../../Actions/CurrentPermitActions/actions";
import { getAPIResponse } from "../../Selectors/APIResponseReducer";
import BulkImportModal from "../Modal/BulkImportModal";
import PermitShareGroupModal from "./PermitShareGroupModal";
import CustomContentAlert, {
  isEmailValid,
  // regExForPhone,
  phoneValidation,
  validateCardNumber,
} from "../helper";
import Loader from "../Loader/Loader";
import apiType from "../../Actions/APIResponseActions/type";
import useGetAllProperty from "../../Hooks/Property/useGetAllProperty";
import DeleteCardModal from "../Modal/DeleteCardModal";
import { Translation } from "react-i18next";
import _i18n from "../Language/i18n";

const intialErrorData = {
  first_name: "",
  last_name: "",
  email: "",
  plate_number: "",
  contact_number: "",
  owner_reference: "",
  card_name: "",
  card_email: "",
  card_cvv: "",
  card_number: "",
  card_expiry: "",
  stall_id: "",
  cardError: false,
};

const UnitCreatePermits = (props) => {
  const [formData, setFormData] = useState({
    first_name: "",
    isFormValid: false,
    last_name: "",
    email: "",
    country_code: "",
    contact_number: "",
    plate_number: "",
    owner_reference: "",
    access_card: "",
    stall_id: "",
    original_stall_id: "",
    is_auto_renew: true,
    autoRenewHide: false,
    is_send_mail: true,
    suite_id: "",
    selectedPermitGroupId: "",
    country_code_list: [
      { id: 1, text: "+1", value: "+1" },
      { id: 2, text: "+91", value: "+91" },
      { id: 3, text: "+44", value: "+44" },
      { id: 4, text: "+61", value: "+61" },
    ],
    phone_options: [],
    unitPaymentSetting: [],
    permitOptionsList: [],
    credit_card_name: "",
    card_email: "",
    card_expiry: "",
    card_cvv: "",
    card_number: "",
    showPermitsImportModal: false,
    startDate: "",
    showPermitsGroupModal: false,
    lpn_options: [],
    unit_credit_card: false,
    disable_card_options: false,
    show_delete_card_model: false,
    isDeleteCard: false,
    card_details_id: "",
  });
  const [responseTableData, setResponseTableData] = useState([]);
  const [validations, setValidations] = useState(intialErrorData);
  const [isSubmit, setIsSubmit] = useState(false);
  const [modelLoader, setmodelLoader] = useState(false);
  const [startDateDisable, setStartDateDisable] = useState(false);
  const [lotPackage, setLotPackage] = useState({});
  const [loader, setLoader] = useState(false);
  const [lpn_btn_status, setLpn_btn_status] = useState(true);
  const [alert, setAlert] = useState(false);
  const [groupButton, setGroupButton] = useState(true);
  const [permitTypes, setPermitTypes] = useState(false);
  const [stallType, setStallType] = useState("");
  const dispatch = useDispatch();

  const PropertyId = useSelector((state) => state.UserProfileDetails?.property?.id);

  const userType = useSelector((state) => state.UserProfileDetails.user_type);
  const unitLotsPackages = useSelector((state) => state.PermitOptionsDetails.unitPaymentSetting.lot_packages);

  const { propertyId: property_id } = useGetAllProperty(PropertyId);
  const allStallData = useSelector((state) => state.ResidenDetails.stall_list);
  console.log("allStallData::::::::::::::::;", allStallData);
  const timezone = useSelector((state) => state.UserProfileDetails?.timezone);

  const permission_id = useSelector((state) => state.UserProfileDetails?.permission_id);
  const fetchedResidentList = useSelector((state) => state.CurrentPermitDetails?.residentList);

  const selectedLang = useSelector((state) => state.CurrentPermitDetails?.updateUnitLanguage);

  const permitGroupList = useSelector((state) => state.PermitOptionsDetails?.getPermitShareGroupList);

  const unitOptionsList = useSelector((state) => state.PermitOptionsDetails?.unitOptionsList);

  const getFilteredResidentlist = () => {
    return fetchedResidentList?.filter((resident) => (unitOptionsList ?? [])?.find((ele) => ele?.suite_id === resident?.suite_id));
  };

  const residentList = getFilteredResidentlist();
  const suite_id = useSelector((state) => state.UserProfileDetails?.suite_id);
  const UnitId = useSelector((state) => state.UserProfileDetails?.id);

  const unitPaymentSetting = useSelector((state) => state.PermitOptionsDetails?.unitPaymentSetting);

  const cardDetailData = useSelector((state) => state.PermitOptionsDetails?.cardDetails);

  const { api_success_message, api_error_message } = useSelector(getAPIResponse);
  let paidPermitOptionListData = useSelector((state) => state.PermitOptionsDetails.allPermitOptionsList);

  useEffect(() => {
    const suite_id_ = formData.suite_id ? formData.suite_id : UnitId;
    if (property_id && suite_id) {
      dispatch(findUnitSettings(property_id, suite_id));
    }
    if (property_id && suite_id_ && Object.keys(lotPackage).length > 0) {
      dispatch(getAllPermitGroupList(property_id, suite_id_, lotPackage.lot_id, lotPackage.id));
    } else {
      setGroupButton(true);
      setFormData({
        ...formData,
        lpn_options: [],
      });
    }
  }, [formData.suite_id, property_id, lotPackage]);

  const handleAddLpnCarDescription = () => {
    setFormData({
      ...formData,
      lpn_options: [...formData.lpn_options, { lpn: "" }],
      selectedPermitGroupId: "",
    });
  };

  const editCardDetails = () => {
    setFormData((prevState) => ({
      ...prevState,
      disable_card_options: !formData.disable_card_options,
      card_name: formData.credit_card_name,
      card_email: formData.card_email,
      card_number: "",
      card_expiry: "",
      card_cvv: "",
      unit_credit_card: true,
    }));
  };

  const handleDeleteCard = () => {
    setFormData((prevState) => ({
      ...prevState,
      isDeleteCard: true,
      show_delete_card_model: false,
    }));

    dispatch(DeleteCreditCardDetails(formData.card_details_id));
  };

  const handleShowDeleteCardModal = () => {
    setFormData({
      ...formData,
      isDeleteCard: false,
      show_delete_card_model: !formData.show_delete_card_model,
    });
  };

  const deleteCardDetails = () => {
    setFormData((prevState) => ({
      ...prevState,
      show_delete_card_model: true,
      isDeleteCard: false,
    }));
  };

  useEffect(() => {
    const suiteId = formData.suite_id || UnitId;
    let groupButtons = groupButton;
    let startDate = false;
    let lpnBtnStatus = lpn_btn_status;

    if (formData.lpn_options.length > 0) {
      groupButtons = true;
    } else if (suiteId && Object.keys(lotPackage).length > 0) {
      groupButtons = false;
    } else if (suiteId && Object.keys(lotPackage).length <= 0) {
      groupButtons = true;
    }

    if (formData.selectedPermitGroupId) {
      startDate = true;
      lpnBtnStatus = true;
    }

    if (permitTypes) {
      groupButtons = permitTypes;
      startDate = true;
    }
    console.log("aaaaaaaaaaaaaaaaaaaaaaa");
    console.log("groupButtons:::::", groupButtons);
    setGroupButton(groupButtons);
    setStartDateDisable(startDate);
    setLpn_btn_status(lpnBtnStatus);
  }, [formData.lpn_options, formData.suite_id, lotPackage, formData.selectedPermitGroupId]);

  const checkLpnValidation = () => {
    const allLpn = [formData.plate_number, ...formData.lpn_options.map((item) => item.lpn)];
    const checkValueBlank = allLpn?.filter((item) => item === "");
    if (checkValueBlank.length === 0 && allLpn.length <= 2) {
      setLpn_btn_status(false);
    } else {
      setLpn_btn_status(true);
    }

    const duplicateLPN = allLpn?.filter((item, index) => allLpn.indexOf(item) !== index);
    if (duplicateLPN.length >= 1) {
      setValidations({
        ...validations,
        plate_number: <Translation>{(t) => <>{t(`Duplicate Plate Number Exists`)}</>}</Translation>,
      });
      return false;
    } else {
      setValidations({
        ...validations,
        plate_number: "",
      });
      return true;
    }
  };

  useEffect(() => {
    checkLpnValidation();
  }, [formData.plate_number, formData.lpn_options]);

  const handleLpnCarOptions = (index, e, type) => {
    const {
      target: { value },
      //  target,
    } = e;
    const { lpn_options } = formData;
    if (lpn_options.length < 2) {
      setLpn_btn_status(false);
    }
    let lpn_values;
    setFormData({
      ...formData,
      lpn_options: lpn_options.map((item, i) => {
        const obj = { ...item };
        if (i === index && type === "lpn_options") {
          lpn_values = value.toUpperCase().replace(/[^a-zA-Z0-9]/g, "");
          obj.lpn = lpn_values;
        }
        return obj;
      }),
    });
  };

  const removeLpnCarOption = (index) => {
    setFormData({
      ...formData,
      lpn_options: [...(formData.lpn_options?.filter((_x, i) => i !== index) || [])],
    });
  };

  const getUTCWT = useCallback(() => {
    let currentdate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    if (formData.startDate) {
      const date = new Date(formData.startDate);
      currentdate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }
    const formatEndDate = moment(currentdate).format("YYYY-MM-DD");
    const endDateWithTime = `${formatEndDate} 23:59:59`;
    const formatedET = moment(endDateWithTime).format("YYYY-MM-DD HH:mm:SS");
    const convertedExpireTime = moment.tz(formatedET, timezone).format();
    return moment.tz(convertedExpireTime, "UTC").format();
  }, [timezone, formData.startDate]);

  useEffect(() => {
    dispatch(getAllPermitOptions(property_id, true));
    if (permission_id !== 2) {
      dispatch(fetchResidentList());
      dispatch(getAllUnitPermitList(property_id));
    }
    return () => {
      setIsSubmit(false);
    };
  }, []);

  useEffect(() => {
    _i18n.changeLanguage(selectedLang);
  }, [selectedLang]);

  useEffect(() => {
    if (paidPermitOptionListData?.length) {
      if (userType === "unit") {
        paidPermitOptionListData = (paidPermitOptionListData ?? []).filter(
          (item) =>
            unitLotsPackages?.map((item) => item.package_name).includes(item.package_name) &&
            unitLotsPackages?.map((item) => item.lot_id).includes(item.lot_id),
        );
        paidPermitOptionListData = onePackageWithAutoSelect(paidPermitOptionListData);
        setFormData((prevState) => ({
          ...prevState,
          permitOptionsList: paidPermitOptionListData,
        }));
      }
    }
  }, [paidPermitOptionListData, userType]);

  useEffect(() => {
    if (unitPaymentSetting) {
      const unit_id = formData.suite_id ? formData.suite_id : UnitId;
      const suite_id_to_be_passed = residentList.find((resident) => resident.id === unit_id)?.suite_id || suite_id;
      if (suite_id_to_be_passed) {
        dispatch(getCardDetails(property_id, suite_id_to_be_passed));
      }
      if (unitPaymentSetting.payment_type === "unit_card" && permission_id === 2 && !cardDetailData) {
        setFormData((prevState) => ({
          ...prevState,
          unit_credit_card: true,
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          unit_credit_card: false,
        }));
        exceptUnitUserType();
      }
    }
  }, [unitPaymentSetting]);

  const exceptUnitUserType = () => {
    if (userType !== "unit") {
      paidPermitOptionListData = (paidPermitOptionListData ?? []).filter(
        (item) =>
          (unitPaymentSetting?.lot_packages ?? []).map((item) => item.package_name).includes(item.package_name) &&
          (unitPaymentSetting?.lot_packages ?? []).map((item) => item.lot_id).includes(item.lot_id),
      );

      paidPermitOptionListData = onePackageWithAutoSelect(paidPermitOptionListData, unitPaymentSetting);
      setFormData((prevState) => ({
        ...prevState,
        permitOptionsList: paidPermitOptionListData,
      }));
    }
  };

  const onePackageWithAutoSelect = (paidPermitOptionListData, unitPaymentSetting) => {
    console.log("onePackageWithAutoSelect:::::1111", paidPermitOptionListData);
    if (paidPermitOptionListData?.length === 1) {
      setStallType(paidPermitOptionListData[0].stall_type);
      if (paidPermitOptionListData[0].stall_type === "user_selected") {
        // getAllUnitStall;
        dispatch(getAllUnitStall(paidPermitOptionListData[0].property_id, paidPermitOptionListData[0].lot_id));
      }
      if (unitPaymentSetting?.lot_packages?.length === 1 && +unitPaymentSetting?.lot_packages[0]?.issued >= +unitPaymentSetting?.lot_packages[0]?.max_permit) {
        paidPermitOptionListData.map((item) => {
          item.is_selected = false;
          return item;
        });
      } else {
        paidPermitOptionListData.map((item) => {
          item.is_selected = true;
          return item;
        });
      }

      if (paidPermitOptionListData[0]?.permit_type === "usage_permits") {
        setPermitTypes(true);
        setFormData((setState) => ({
          ...setState,
          is_auto_renew: false,
          autoRenewHide: true,
        }));
      } else {
        setFormData((setState) => ({
          ...setState,
          is_auto_renew: true,
          autoRenewHide: false,
        }));
      }
    } else {
      paidPermitOptionListData.map((item) => {
        item.is_selected = false;
        return item;
      });
    }
    return paidPermitOptionListData;
  };

  useEffect(() => {
    if (unitPaymentSetting.payment_type === "unit_card") {
      if (cardDetailData && Object.keys(cardDetailData).length) {
        setFormData((prevState) => ({
          ...prevState,
          disable_card_options: true,
          unit_credit_card: false,
          credit_card_name: cardDetailData?.name,
          card_email: cardDetailData?.email,
          card_number: cardDetailData.card_number, // `************${cardDetailData?.last_four_card_number}`,
          card_expiry: cardDetailData?.expiry_date,
          card_details_id: cardDetailData?.id,
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          unit_credit_card: true,
          disable_card_options: false,
          credit_card_name: "",
          card_email: "",
          card_number: "",
          card_expiry: "",
          card_cvv: "",
          card_details_id: "",
        }));
      }
    }
  }, [cardDetailData]);

  const handleChange = useCallback(
    ({ target }) => {
      if (target.type === "checkbox") {
        const value = !formData[target.name];
        setFormData((prevState) => ({
          ...prevState,
          [target.name]: value,
        }));
      } else {
        if (target.name === "plate_number") {
          setFormData((prevState) => ({
            ...prevState,
            [target.name]: target.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, ""),
          }));
        } else if (target.name === "contact_number") {
          setFormData((prevState) => ({
            ...prevState,
            [target.name]: target.value.replace(/\D/gi, ""),
          }));
          const obj = {
            ...validations,
            contact_number: phoneValidation({
              phone: target.value.replace(/\D/gi, ""),
            }),
          };
          setValidations(obj);
        } else if (target.name === "access_card") {
          setFormData((prevState) => ({
            ...prevState,
            [target.name]: target.value.replace(/\D/gi, ""),
          }));
        } else if (target.name === "email" || target.name === "card_email") {
          isEmailValidation(target);
        } else if (target.name === "card_number") {
          handleCardchange(target);
        } else if (target.name === "card_cvv") {
          handleCvvChange(target);
        } else if (target.name === "stall_id") {
          const selectedStall = target.selectedOptions[0];
          setFormData((prevState) => ({
            ...prevState,
            [target.name]: selectedStall.value,
            original_stall_id: selectedStall.getAttribute("id"),
          }));
        } else if (target.name === "driver_stall_id") {
          setFormData((prevState) => ({
            ...prevState,
            stall_id: target.value,
          }));
        } else {
          setFormData((prevState) => ({
            ...prevState,
            [target.name]: target.value,
          }));
        }
      }

      if (target.name !== "card_number" && target.name !== "email" && target.name !== "card_email") {
        const obj = { ...formData, [target.name]: target.value };
        isSubmit && handleError(obj);
      }
    },
    [isSubmit, formData],
  );

  const handleDateChange = useCallback(
    (target) => {
      setFormData((prevState) => ({
        ...prevState,
        startDate: target,
      }));
    },
    [isSubmit, formData],
  );

  const handleCvvChange = (target) => {
    const cvvdata = !/^\d+$/.test(target.value);

    if ((!cvvdata && target.value.length <= 4) || target.value.length === 0) {
      const errors = {};
      setFormData((prevState) => ({
        ...prevState,
        card_cvv: target.value,
      }));
      if (target.value.length >= 3) {
        errors.card_cvv = "";
        setValidations((prevState) => ({
          ...prevState,
          [target.name]: "",
        }));
      } else if (target.value.length > 1) {
        setValidations((prevState) => ({
          ...prevState,
          [target.name]: "CVV should be at least 3 digits.",
        }));
      } else {
        setValidations((prevState) => ({
          ...prevState,
          [target.name]: "",
        }));
      }
    }
  };

  const isEmailValidation = async (target) => {
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
    if (target.value) {
      const regExForEmail = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      const isEmailValidate = regExForEmail.test(String(target.value).toLowerCase());
      if (!isEmailValidate) {
        setValidations((prevState) => ({
          ...prevState,
          [target.name]: <Translation>{(t) => <>{t("Please Enter valid email")}</>}</Translation>,
        }));
      } else {
        setValidations((prevState) => ({
          ...prevState,
          [target.name]: "",
        }));
      }
    } else {
      setValidations((prevState) => ({
        ...prevState,
        [target.name]: <Translation>{(t) => <>{t("Email can't be empty")}</>}</Translation>,
      }));
    }
  };

  const handleCardchange = (target) => {
    const cardData = !/^\d+$/.test(target.value);
    if ((!cardData && target.value.length <= 16) || target.value.length === 0) {
      setFormData({
        ...formData,
        [target.name]: target.value,
      });
      if (target.value.length > 14) {
        const validCard = validateCardNumber(target.value);
        if (validCard) {
          setValidations({
            ...validations,
            card_number: "",
            cardError: false,
          });
        } else {
          setValidations({
            ...validations,
            card_number: "Please enter valid card number",
            cardError: true,
          });
        }
      } else {
        setValidations({
          ...validations,
          card_number: "Please enter 16 digit card number",
          cardError: true,
        });
      }
    }
  };

  useEffect(() => {
    if (formData?.suite_id && residentList && residentList?.length) {
      const suite_id_to_be_passed = (residentList ?? []).find((resident) => resident.id === formData.suite_id)?.suite_id;
      if (property_id && suite_id_to_be_passed) {
        dispatch(findUnitSettings(property_id, suite_id_to_be_passed));
      }
      if (unitPaymentSetting.payment_type === "unit_card") {
        dispatch(getCardDetails(property_id, suite_id_to_be_passed));
        setFormData((prevState) => ({
          ...prevState,
          unit_credit_card: true,
        }));
      }
    }
  }, [formData?.suite_id]);

  const handleSelectedSuiteId = async (e) => {
    setPermitTypes(false);
    if (e.target.value.toString().length) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        startDate: "",
        stall_id: "",
        original_stall_id: "",
      });
      setStallType("");
      setValidations({
        ...validations,
        suite_id: "",
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        startDate: "",
      });
      setValidations({
        ...validations,
        suite_id: "Please select unit",
      });
    }
    setValidations({});
    setLotPackage({});
  };

  const handleAddPhoneOptions = () => {
    const { phone_options } = formData;
    setFormData((prevState) => ({
      ...prevState,
      phone_options: [...phone_options, { country_code: "+1", phone: "" }],
    }));
  };

  const isMultiplePhoneValid = () => {
    const { phone_options, country_code, contact_number } = formData;
    const phoneListCount = {};
    const phoneList = phone_options.map((item) => {
      return item.country_code + item.phone;
    });
    const phoneNumberCountry_code = country_code === "" ? "+1" : country_code;
    const phoneNumber = contact_number === "" ? "" : contact_number;
    phoneList.push(phoneNumberCountry_code + phoneNumber);
    phoneList.forEach(function (x) {
      phoneListCount[x] = (phoneListCount[x] || 0) + 1;
    });
    const phoneListStatus = Object.keys(phoneListCount)?.filter((item) => phoneListCount[item] > 1);
    const validPhone = phone_options?.map((options) => {
      if (options.phone?.trim().length > 10 || options.phone?.trim().length < 10 || options.phone?.trim().length === 0 || phoneListStatus.length !== 0) {
        return !(options.phone.trim().length > 10 || options.phone.trim().length < 10 || options.phone.trim().length === 0 || phoneListStatus.length !== 0);
      }
      return true;
    });
    return !validPhone.includes(false);
  };

  const handleSubmit = async () => {
    setIsSubmit(true);
    const {
      permitOptionsList,
      first_name,
      last_name,
      email,
      is_auto_renew,
      is_send_mail,
      owner_reference,
      access_card,
      stall_id,
      original_stall_id,
      plate_number,
      lpn_options,
      credit_card_name,
      card_email,
      card_expiry,
      card_cvv,
      card_number,
      startDate,
      selectedPermitGroupId,
    } = formData;
    const card_expiry_ = moment(new Date(card_expiry)).format("YYYY-MM-DD hh:mm:ss");
    let UTCstartDate = "";
    if (startDate) {
      const formatEndDate = moment(startDate).format("YYYY-MM-DD");
      const endDateWithTime = `${formatEndDate} 00:00:00`;
      const formatedET = moment(endDateWithTime).format("YYYY-MM-DD HH:mm:SS");
      const convertedExpireTime = moment.tz(formatedET, timezone).format();
      UTCstartDate = moment.tz(convertedExpireTime, "UTC").format();
    }
    const dataToSave = [];
    const isAllFieldsValidated = validateFields(formData);
    await handleError(formData);
    const permitOptionsArray = [];
    permitOptionsList.forEach((permitOption) => permitOptionsArray.push(permitOption.is_selected));
    let _suite_id;
    if (permission_id !== 2) {
      _suite_id = (residentList || []).find((resident) => resident.id === formData.suite_id)?.suite_id;
    }
    const end_time = getUTCWT();
    if (permitOptionsArray.includes(true) && isAllFieldsValidated) {
      if (permitOptionsList?.length) {
        permitOptionsList.map((item) => {
          const permitPackages = (unitPaymentSetting.lot_packages ?? []).filter(
            (lot) => lot.lot_id === item.lot_id && lot.package_name === item.package_name,
          )?.[0];
          if (item.is_selected) {
            const obj = {
              first_name: first_name,
              last_name: last_name,
              email: email,
              auto_renew: is_auto_renew,
              send_mail: is_send_mail,
              lpn: plate_number,
              permit_type: item?.permit_type,
              cost: item.cost,
              expire_in_days: item?.expire_in_days,
              usage_count: item.usage_count,
              package_name: item.package_name,
              payment_type: unitPaymentSetting?.payment_type,
              owner_reference: owner_reference,
              access_card: access_card,
              stall_id: stall_id,
              original_stall_id: original_stall_id || null,
              stall_type: stallType,
              daily_amount: item.daily_amount,
              max_amount: item.max_amount,
              max_permit_count: permitPackages?.max_permit,
              permit_issued_count: +permitPackages?.issued,
              max_permit_count_as_per_lot: item?.max_permit_count,
              lot_id: item.lot_id,
              paid_permit_option_id: item.id,
              country_code: item.country_code,
              end_time: end_time,
              phone: formData.contact_number,
              permission_id: permission_id,
              startDate: UTCstartDate,
              timezone: timezone,
              group_id: selectedPermitGroupId,
              language: selectedLang,
              lpn_options: lpn_options,
              ...(permission_id !== 2 && { suite_id: _suite_id }),
            };
            if (unitPaymentSetting.payment_type === "unit_card") {
              if (formData.unit_credit_card) {
                obj.credit_card_info = {
                  name: credit_card_name,
                  email: card_email,
                  card_expiry: card_expiry_,
                  cvv: card_cvv,
                  cc_number: card_number,
                };
                obj.unit_credit_card = true;
              } else {
                obj.unit_credit_card = false;
              }
            }
            dataToSave.push(obj);
          }

          return item;
        });
        dataToSave.isImport = false;
        setLoader(true);
        setFormData((prevState) => ({
          ...prevState,
          isFormValid: true,
        }));
        dispatch(addPermitsByUnit(dataToSave));
      }
    }
  };
  useEffect(() => {
    setLoader(false);
    setmodelLoader(false);
    isSubmitEnabled();
    setFormData((prevState) => ({
      ...prevState,
      isFormValid: false,
    }));

    if (api_success_message || api_error_message) {
      if (isSubmit && api_success_message) {
        resetForm();
      }
      if (api_success_message === "Card Details deleted successfully!" && userType === "unit") {
        setTimeout(() => {
          dispatch(getCardDetails(property_id, suite_id));
        }, 1000);
      }
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
        dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: "",
        });
        dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: "",
        });
      }, 4000);
    }
    setTimeout(() => {
      setAlert(false);
      dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: "",
      });
      dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: "",
      });
    }, 4000);
  }, [api_success_message, api_error_message]);

  const isSubmitEnabled = () => {
    if (isSubmit) {
      if (api_success_message && suite_id) {
        if (property_id && suite_id) {
          dispatch(findUnitSettings(property_id, suite_id));
        }
        setFormData({
          ...formData,
          suite_id: suite_id,
        });
        setValidations({
          ...validations,
          suite_id: "",
        });
      }
    }
  };

  const resetForm = () => {
    setFormData((prevState) => ({
      ...prevState,
      first_name: "",
      last_name: "",
      email: "",
      contact_number: "",
      plate_number: "",
      owner_reference: "",
      access_card: "",
      stall_id: "",
      startDate: "",
      is_auto_renew: true,
      autoRenewHide: false,
      is_send_mail: true,
      phone_options: [],
      permitOptionsList: [],
      selectedPermitGroupId: "",
      lpn_options: [],
      suite_id: "",
      ...(formData.unit_credit_card && {
        credit_card_name: "",
        card_email: "",
        card_expiry: "",
        card_cvv: "",
        card_number: "",
      }),
    }));
    const { permitOptionsList } = formData;
    permitOptionsList.forEach((permitOption) => {
      permitOption.is_selected = false;
    });
    setFormData((prevState) => ({
      ...prevState,
      permitOptionsList: permitOptionsList,
    }));
    setIsSubmit(false);
    setLoader(false);
    setmodelLoader(false);
    setValidations(intialErrorData);
    setGroupButton(true);
    setLotPackage({});
  };

  const handleError = async (data) => {
    const permitOptionsArray = [];
    formData.permitOptionsList.forEach((permitOption) => permitOptionsArray.push(permitOption.is_selected));
    data.phone = data.contact_number;
    let permitOptions = "";
    const unitPermitPackages = formData.permitOptionsList.map((item) => {
      const permitPackages = unitPaymentSetting?.lot_packages?.filter((lot) => lot.lot_id === item.lot_id && lot.package_name === item.package_name)?.[0];
      item.permitPackages = permitPackages;
      return item;
    });
    const filterMaxUnitPermitPackaes = unitPermitPackages.filter((item) => item?.permitPackages?.issued === parseInt(item?.permitPackages?.max_permit));
    if (unitPermitPackages.length !== 0 && filterMaxUnitPermitPackaes.length === unitPermitPackages.length) {
      permitOptions = <Translation>{(t) => <>{t("All permits issued limit reach to Allowed to issued.")}</>}</Translation>;
    } else if (!permitOptionsArray.includes(true)) {
      permitOptions = <Translation>{(t) => <>{t("Select at least one option")}</>}</Translation>;
    } else {
      permitOptions = "";
    }

    const obj = {
      ...validations,
      contact_number: !data.phone ? <Translation>{(t) => <>{t("Phone can't be empty")}</>}</Translation> : phoneValidation(data),
      permit_option: permitOptions,
      first_name: !data.first_name ? <Translation>{(t) => <>{t("First name can't be empty")}</>}</Translation> : "",
      last_name: !data.last_name ? <Translation>{(t) => <>{t("Last name can't be empty")}</>}</Translation> : "",
      stall_id: !data.stall_id && stallType === "user_selected" ? <Translation>{(t) => <>{t("Stall can't be empty")}</>}</Translation> : "",
      plate_number: "",
      email: validations.email || data.email ? validations.email : <Translation>{(t) => <>{t("Email can't be empty")}</>}</Translation>,
      owner_reference: !data.owner_reference && !props.isSemiLogin ? <Translation>{(t) => <>{t("Owner reference can't be empty")}</>}</Translation> : "",
      phone_options: !data.phone_options ? <Translation>{(t) => <>{t("Phone can't be empty")}</>}</Translation> : "",
      phone_number_options:
        formData.phone_options.length > 0 &&
        (!formData.phone_options[formData.phone_options.length - 1].phone ? (
          <Translation>{(t) => <>{t("Phone can't be empty")}</>}</Translation>
        ) : (
          getPhoneValidation()
        )),
      ...(permission_id !== 2 && {
        suite_id: !data.suite_id ? "Please select unit" : "",
      }),
      ...(formData.unit_credit_card && {
        ...validateCardData(data),
      }),
    };

    if (!data.plate_number) {
      obj.plate_number = <Translation>{(t) => <>{t("LPN can't be empty")}</>}</Translation>;
    } else if (!checkLpnValidation()) {
      obj.plate_number = <Translation>{(t) => <>{t(`Duplicate Plate Number Exists`)}</>}</Translation>;
    } else {
      obj.plate_number = "";
    }
    setValidations(obj);
  };

  const getPhoneValidation = () => {
    const isMultipleNumber = !isMultiplePhoneValid() ? <Translation>{(t) => <>{t("Duplicate guest contact number is not allowed")}</>}</Translation> : "";
    return phoneValidation(formData.phone_options[formData.phone_options.length - 1]) === "" ? isMultipleNumber : "";
  };
  const getCardNumberValidation = (data) => {
    return data.card_cvv.length < 3 ? <Translation>{(t) => <>{t("CVV should be at least 3 digits.")}</>}</Translation> : "";
  };

  const validateCardData = (data) => {
    const obj = {};
    obj.card_name = !data.credit_card_name ? <Translation>{(t) => <>{t("Name can't be empty")}</>}</Translation> : "";
    obj.card_email = !data.card_email ? <Translation>{(t) => <>{t("Email can't be empty")}</>}</Translation> : "";
    obj.card_cvv = !data.card_cvv ? <Translation>{(t) => <>{t("CVV can't be empty")}</>}</Translation> : getCardNumberValidation(data);
    obj.card_number = data.card_number ? validations.card_number : <Translation>{(t) => <>{t("Card Number can't be empty")}</>}</Translation>;
    obj.card_expiry = !data.card_expiry ? <Translation>{(t) => <>{t("Expiry Date can't be empty")}</>}</Translation> : "";
    return obj;
  };

  const validateFields = (permitFormData) => {
    const {
      first_name,
      last_name,
      email,
      owner_reference,
      // access_card,
      stall_id,
      plate_number,
      credit_card_name,
      card_email,
      card_expiry,
      card_number,
      contact_number,
      unit_credit_card,
      permitOptionsList,
      suite_id: permit_suite_id,
      phone_options,
    } = permitFormData;
    let isCardDetails = true;
    let isSuiteId = true;
    let otherPhone = true;
    let isStallId = true;
    if (phone_options.length > 0) {
      otherPhone = phone_options[phone_options.length - 1].phone.length === 10;
    }
    console.log("xxx111", unit_credit_card, permission_id);
    if (unit_credit_card && permission_id === 2) {
      isCardDetails = !!(credit_card_name && card_email && card_expiry && card_number);
    }
    if (permission_id !== 2) {
      isSuiteId = !!permit_suite_id;
    }
    const permitOptionsArray = [];
    permitOptionsList.forEach((permitOption) => permitOptionsArray.push(permitOption.is_selected));
    const isEmailValidated = isEmailValid(email);
    const isAllLPNValidated = checkLpnValidation();
    const isOwnerRefValid = !props.isSemiLogin && userType !== "unit" ? owner_reference : true;
    if (stallType === "user_selected" && !stall_id) {
      isStallId = false;
    }
    return !!(
      first_name &&
      last_name &&
      email &&
      contact_number.length === 10 &&
      otherPhone &&
      isOwnerRefValid &&
      // access_card &&
      isStallId &&
      plate_number &&
      isCardDetails &&
      isEmailValidated &&
      isAllLPNValidated &&
      permitOptionsArray.includes(true) &&
      isSuiteId
    );
  };

  const handleCalender = async (date) => {
    setFormData((prevState) => ({
      ...prevState,
      card_expiry: date,
    }));
    setValidations((prevState) => ({
      ...prevState,
      card_expiry: date ? "" : <Translation>{(t) => <>{t("Expiry Date can't be empty")}</>}</Translation>,
    }));
  };

  const handlePermitoptions = async (id, key, permit_share_option_disable) => {
    const { permitOptionsList: data } = formData;
    const permitOptionsList = [...data];
    const updatedValues = permitOptionsList.map((i) => {
      if (i.id === id) {
        i.is_selected = true;
        setStallType(i.stall_type);
        if (i.stall_type === "user_selected") {
          dispatch(getAllUnitStall(i.property_id, i.lot_id));
        }
        setFormData((setState) => ({
          ...setState,
          stall_id: "",
          original_stall_id: "",
        }));
      } else {
        i.is_selected = false;
      }
      return i;
    });
    setFormData((setState) => ({
      ...setState,
      permitOptionsList: updatedValues,
      startDate: "",
      is_auto_renew: !permit_share_option_disable,
      autoRenewHide: permit_share_option_disable,
    }));

    setPermitTypes(permit_share_option_disable);
    setStartDateDisable(permit_share_option_disable);
  };

  useEffect(() => {
    for (const i of formData.permitOptionsList) {
      if (i.is_selected === true) {
        setLotPackage(i);
      }
    }
  }, [formData.permitOptionsList]);

  const openPermitsImportModal = () => {
    setFormData((prevState) => ({
      ...prevState,
      showPermitsImportModal: !formData.showPermitsImportModal,
      suite_id: "",
    }));
  };

  const openClosePermitsGroupModal = () => {
    setFormData((prevState) => ({
      ...prevState,
      showPermitsGroupModal: !formData.showPermitsGroupModal,
    }));
  };

  const handleFileUpload = (jsonObject) => {
    setmodelLoader(true);
    setResponseTableData([...jsonObject]);
    jsonObject.isImport = true;
    dispatch(addPermitsByUnit(jsonObject));
  };

  const handlePhoneOptions = (index, value, type) => {
    const { phone_options } = formData;
    let phone_values;
    let country_code_values;
    if (type === "phone_number_options") {
      phone_values = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      phone_options[index].phone = phone_values;
    }
    if (type === "country_code_options") {
      country_code_values = value;
      phone_options[index].country_code = country_code_values;
    }
    setFormData((prevState) => ({
      ...prevState,
      phone_options: [...phone_options],
    }));
    setValidations((prevState) => ({
      ...prevState,
      phone_number_options: getPhoneNumberOption(phone_options, index),
    }));
    const obj = { ...formData, phone_options: [...phone_options] };
    isSubmit && handleError(obj);
  };

  const getPhoneNumberOption = (phone_options, index) => {
    const isMultiplePhoneOptionValid = !isMultiplePhoneValid() ? (
      <Translation>{(t) => <>{t("Duplicate guest contact number is not allowed")}</>}</Translation>
    ) : (
      ""
    );
    const isPhoneValid = phoneValidation(phone_options[index]) === "" ? isMultiplePhoneOptionValid : "";
    const phoneOptionValue = !phone_options[index].phone ? <Translation>{(t) => <>{t("Phone can't be empty")}</>}</Translation> : isPhoneValid;
    return phoneOptionValue;
  };

  const removePhoneOption = (index) => {
    const { phone_options } = formData;
    setFormData((prevState) => ({
      ...prevState,
      phone_options: [...phone_options.filter((_x, i) => i !== index)],
    }));
  };

  const handleSelectedGroup = (e) => {
    if (e.target.value) {
      setFormData((prevState) => ({
        ...prevState,
        selectedPermitGroupId: e.target.value,
        startDate: "",
      }));
      setStartDateDisable(true);
    } else {
      setFormData((prevState) => ({
        ...prevState,
        selectedPermitGroupId: e.target.value,
        startDate: "",
      }));
      setStartDateDisable(false);
    }
  };

  const setPermitType = (permit_type) => {
    if (permit_type === "monthly_permits") {
      return "Monthly";
    } else {
      if (permit_type === "usage_permits") {
        return "Usage";
      } else {
        return "";
      }
    }
  };

  const handleIssuedCountRedirect = (permitPackages, unitPaymentSetting) => {
    const history = require("../../history").default;
    history.push({
      pathname: `/current-permits`,
      data: {
        suite_id: unitPaymentSetting?.suite_id,
        lot_id: permitPackages?.lot_id,
        package_name: permitPackages?.package_name,
        activeTab: "permitpackages",
        status: "issued_count",
      },
    });
  };

  const handleBackToVisitor = () => {
    const history = require("../../history").default;
    history.push({
      pathname: `/visitor-permit`,
    });
  };

  const handleBackToPermitPackage = () => {
    const history = require("../../history").default;
    history.push({
      pathname: `/current-permits`,
      data: {
        activeTab: "permitpackages",
      },
    });
  };

  const multiplePhoneRender = (phone_options, country_code_list) => {
    return (
      phone_options &&
      typeof phone_options === "object" &&
      Array.isArray(phone_options) &&
      phone_options.map((option, index) => {
        return (
          <Row key={option?.country_code} className="my-0 m-w-480px">
            <Col className="col-auto">
              <Form.Group className="mb-3">
                <Form.Control
                  className="w-85px"
                  as="select"
                  name="country_code_options"
                  onChange={(e) => handlePhoneOptions(index, e.target.value, "country_code_options")}
                  value={option?.country_code}
                  required
                >
                  {country_code_list ? (
                    <>
                      {(country_code_list ?? []).map((item) => (
                        <option key={item?.id} value={item.value}>
                          {item.text}
                        </option>
                      ))}
                    </>
                  ) : null}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col className="col p-0">
              <Form.Group className="p-0">
                <Form.Control
                  placeholder="Phone Number"
                  type="text"
                  name="phone_number_options"
                  value={option?.phone}
                  onChange={(e) => handlePhoneOptions(index, e.target.value, "phone_number_options")}
                  maxLength="10"
                  autoComplete="off"
                />
              </Form.Group>
            </Col>
            <Col className="col-auto">
              <Button className="h-40px" size="sm" onClick={() => removePhoneOption(index)}>
                <i className="fas fa-minus"></i>
              </Button>
            </Col>
          </Row>
        );
      })
    );
  };

  const usagePermitPackages = (item, key, permitPackages, unitPaymentSetting) => {
    if (item.permit_type === "usage_permits") {
      return (
        <tr key={item.id}>
          <td>
            {" "}
            <input
              type="checkbox"
              id={key}
              name={`select_permit_options_${key}`}
              checked={item.is_selected}
              onChange={() => handlePermitoptions(item.id, key, true)}
              disabled={
                (permission_id === 2 && Object.keys(unitPaymentSetting).length === 0) || parseInt(permitPackages?.max_permit) <= permitPackages?.issued
                  ? parseInt(permitPackages?.issued)
                  : 0
              }
            />
            <label for={key}> </label>
            <br></br>
          </td>
          <td>{item?.lots?.alias}</td>
          <td>{item?.package_name}</td>
          {/* <td>{item.description}</td> */}
          <td>
            {item?.description ? (
              <OverlayTrigger
                delay={{
                  show: 250,
                  hide: 400,
                }}
                placement={"top"}
                overlay={<Tooltip id={`tooltip-top`}>{item.description}</Tooltip>}
              >
                <div
                  className="text-nowrap text-truncate pr-3"
                  style={{
                    maxWidth: "200px",
                  }}
                >
                  {item.description}
                </div>
              </OverlayTrigger>
            ) : (
              "-"
            )}
          </td>
          <td>{setPermitType(item.permit_type)}</td>
          <td>{item?.stall_type ? _.startCase(item.stall_type).replace(/_/g, " ") : "-"}</td>
          <td>{permitPackages?.max_permit}</td>
          <td>
            <div className="curserPointer" onClick={() => handleIssuedCountRedirect(permitPackages, unitPaymentSetting)}>
              {permitPackages?.issued ? permitPackages?.issued : "-"}
              <br></br>
            </div>
          </td>
          {permission_id === 2 && unitPaymentSetting.payment_type === "free" ? null : (
            <>
              <td>{item?.expire_in_days === "end_of_month" ? "End of Month" : `${+item?.expire_in_days} Days`}</td>
              <td>${+item?.cost}</td>
              <td>{+item?.usage_count}</td>
            </>
          )}
        </tr>
      );
    } else {
      return <></>;
    }
  };

  const monthlyPermitPackages = (item, key, permitPackages, unitPaymentSetting) => {
    if (item?.permit_type === "monthly_permits") {
      return (
        <tr key={item.id}>
          <td>
            {" "}
            <input
              type="checkbox"
              id={key}
              name={`select_permit_options_${key}`}
              checked={item.is_selected}
              onChange={() => handlePermitoptions(item.id, key, false)}
              disabled={
                (permission_id === 2 && Object.keys(unitPaymentSetting).length === 0) || parseInt(permitPackages?.max_permit) <= permitPackages?.issued
                  ? parseInt(permitPackages?.issued)
                  : 0
              }
            />
            <label for={key}> </label>
            <br></br>
          </td>
          <td>{item?.lots?.alias}</td>
          <td>{item.package_name}</td>
          {/* <td>{item.description}</td> */}
          <td>
            {item.description ? (
              <OverlayTrigger
                delay={{
                  show: 250,
                  hide: 400,
                }}
                placement={"top"}
                overlay={<Tooltip id={`tooltip-top`}>{item.description}</Tooltip>}
              >
                <div
                  className="text-nowrap text-truncate pr-3"
                  style={{
                    maxWidth: "200px",
                  }}
                >
                  {item.description}
                </div>
              </OverlayTrigger>
            ) : (
              "-"
            )}
          </td>
          <td>{setPermitType(item.permit_type)}</td>
          <td>{item?.stall_type ? _.startCase(item.stall_type).replace(/_/g, " ") : "-"}</td>

          <td>{permitPackages?.max_permit}</td>
          <td>
            <div className="curserPointer" onClick={() => handleIssuedCountRedirect(permitPackages, unitPaymentSetting)}>
              {permitPackages?.issued ? permitPackages?.issued : "-"}
              <br></br>
            </div>
          </td>
          {permission_id === 2 && unitPaymentSetting.payment_type === "free" ? null : (
            <>
              <td>${" " + item?.daily_amount}</td>
              <td>${" " + item?.max_amount}</td>
            </>
          )}
        </tr>
      );
    } else {
      return <></>;
    }
  };

  const showUnitList = (residentList) => {
    return residentList.length ? (
      <>
        {residentList.map((item, index) => (
          <option key={item.id} value={item.id}>
            {item.suite_id}
          </option>
        ))}
      </>
    ) : null;
  };

  const showPermitGroupList = (permitGroupList) => {
    return permitGroupList.length ? (
      <>
        {permitGroupList.map((item) => (
          <option key={item.uuid} value={item.uuid}>
            {`${item.group_name}(${item.max_allowed_in})`}
          </option>
        ))}
      </>
    ) : null;
  };

  const showDeleteCardModal = (formData) => {
    return formData.show_delete_card_model ? (
      <Translation>
        {(t) => (
          <DeleteCardModal
            onOpen={formData.show_delete_card_model}
            onConfirm={handleDeleteCard}
            message={t("Are You sure you want to Delete this card.? It will not auto renew permits.")}
            onClose={handleShowDeleteCardModal}
            title={t("Delete Card")}
            isDeleteCarddisabled={formData.isDeleteCard}
            loading={false}
          />
        )}
      </Translation>
    ) : null;
  };

  const showHeader = (permission_id, unitPaymentSetting) => {
    return permission_id === 2 && unitPaymentSetting.payment_type === "free" ? null : (
      <>
        {" "}
        <th
          style={{
            width: "18%",
            minWidth: "140px",
          }}
        >
          <Translation>{(t) => <>{t("Rate Per Day")}</>}</Translation>
        </th>
        <th
          style={{
            width: "18%",
            minWidth: "140px",
          }}
        >
          <Translation>{(t) => <>{t("Rate Per Month")}</>}</Translation>
        </th>
      </>
    );
  };

  const showCodeList = (formData) => {
    return formData.country_code_list ? (
      <>
        {formData.country_code_list.map((item) => (
          <option key={item?.id} value={item.value}>
            {item.text}
          </option>
        ))}
      </>
    ) : null;
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header className="d-md-flex justify-content-between">
                <Card.Title as="h4">
                  <Translation>{(t) => <>{t(`Issue Permit Packages`)}</>}</Translation>
                </Card.Title>
                {userType === "unit" && (
                  <div className="d-flex flex-wrap" style={{ gap: "8px" }}>
                    <button className="btn btn-blue" type="button" aria-controls="multiCollapseExample2" onClick={handleBackToVisitor}>
                      <i style={{ marginRight: "5px" }} className="fa fa-arrow-left"></i>
                      <Translation>{(t) => <>{t("Add Visitor")}</>}</Translation>{" "}
                    </button>
                    <button className="btn btn-blue" type="button" aria-controls="multiCollapseExample2" onClick={handleBackToPermitPackage}>
                      <i style={{ marginRight: "5px" }} className="fa fa-arrow-left"></i>
                      <Translation>{(t) => <>{t("Permit Packages")}</>}</Translation>{" "}
                    </button>
                  </div>
                )}
              </Card.Header>
              <Card.Body className="pos-rel-overflow-hide">
                {" "}
                {loader ? (
                  <Loader />
                ) : (
                  <>
                    <Row>
                      {permission_id !== 2 && (
                        <Col xl="3" lg="4" md="6" sm="12">
                          <Form.Group className="mb-3">
                            <label>
                              Select unit<span className="asterisk">*</span>
                            </label>
                            <Form.Control as="select" name="suite_id" onChange={(e) => handleSelectedSuiteId(e)} value={formData.suite_id} required>
                              <option value={""}>Select unit</option>
                              {showUnitList(residentList)}
                            </Form.Control>
                          </Form.Group>
                          {validations.suite_id && <div className="help-block">{validations.suite_id}</div>}
                        </Col>
                      )}
                      <Col xl="3" lg="4" md="6" sm="12">
                        <Form.Group className="mb-3">
                          <label>
                            <Translation>{(t) => <>{t(`First Name`)}</>}</Translation>
                            <span className="asterisk">*</span>
                          </label>
                          <Translation>
                            {(t) => (
                              <Form.Control
                                placeholder={t(`First Name`)}
                                type="text"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                                autoComplete="off"
                                disabled={permission_id === 2 && Object.keys(unitPaymentSetting).length === 0}
                                required
                              ></Form.Control>
                            )}
                          </Translation>
                          {validations.first_name && <div className="help-block">{validations.first_name}</div>}
                        </Form.Group>
                      </Col>
                      <Col xl="3" lg="4" md="6" sm="12">
                        <Form.Group className="mb-3">
                          <label>
                            <Translation>{(t) => <>{t(`Last Name`)}</>}</Translation>
                            <span className="asterisk">*</span>
                          </label>
                          <Translation>
                            {(t) => (
                              <Form.Control
                                placeholder={t(`Last Name`)}
                                type="text"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                                disabled={permission_id === 2 && Object.keys(unitPaymentSetting).length === 0}
                                autoComplete="off"
                                required
                              ></Form.Control>
                            )}
                          </Translation>

                          {validations.last_name && <div className="help-block">{validations.last_name}</div>}
                        </Form.Group>
                      </Col>

                      <Col xl="3" lg="4" md="6" sm="12">
                        <Form.Group className="mb-3">
                          <label>
                            <Translation>{(t) => <>{t(`Email`)}</>}</Translation>
                            <span className="asterisk">*</span>
                          </label>
                          <Translation>
                            {(t) => (
                              <Form.Control
                                placeholder={t("Email")}
                                type="text"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                autoComplete="off"
                                disabled={permission_id === 2 && Object.keys(unitPaymentSetting).length === 0}
                                required
                              ></Form.Control>
                            )}
                          </Translation>

                          {validations.email && <div className="help-block">{validations.email}</div>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="5" lg="5" md="6" sm="12">
                        <Row className="align-items-end">
                          <Col style={{ minWidth: "150px" }}>
                            <Form.Group className="mb-3">
                              <label>
                                <Translation>{(t) => <>{t(`Plate Number`)}</>}</Translation>
                                <span className="asterisk">*</span>
                              </label>
                              <Translation>
                                {(t) => (
                                  <Form.Control
                                    placeholder={t("Plate Number")}
                                    type="text"
                                    name="plate_number"
                                    value={formData.plate_number}
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    disabled={permission_id === 2 && Object.keys(unitPaymentSetting).length === 0}
                                    autoComplete="off"
                                    maxLength="11"
                                    required
                                  ></Form.Control>
                                )}
                              </Translation>

                              {validations.plate_number && <div className="help-block">{validations.plate_number}</div>}
                            </Form.Group>
                          </Col>
                          <Col className="col-auto">
                            <Button size="sm" className="h-40px px-1 text-nowrap mb-3" disabled={lpn_btn_status} onClick={handleAddLpnCarDescription}>
                              <Translation>{(t) => <>{t(`Add Plate`)}</>}</Translation>
                            </Button>
                          </Col>
                        </Row>
                        {formData.lpn_options &&
                          typeof formData.lpn_options === "object" &&
                          Array.isArray(formData.lpn_options) &&
                          formData.lpn_options.map((option, index) => {
                            return (
                              <Row key={option?.country_code} className="">
                                <Col className="">
                                  <Form.Group>
                                    <Form.Label>
                                      {" "}
                                      <Translation>{(t) => <>{t(`Plate Number`)}</>}</Translation>
                                      {index + 1}
                                    </Form.Label>
                                    <span className="asterisk">*</span>
                                    <Form.Control
                                      type="text"
                                      name="lpn_options"
                                      onChange={(e) => handleLpnCarOptions(index, e, "lpn_options")}
                                      value={option?.lpn}
                                      maxLength="11"
                                      required
                                    ></Form.Control>
                                  </Form.Group>
                                </Col>
                                <Col className="col-auto pl-0">
                                  <div>
                                    <label>&nbsp;</label>
                                  </div>
                                  <Button size="sm" className="h-40px px-1" onClick={() => removeLpnCarOption(index)}>
                                    <Translation>{(t) => <>{t(`Remove Plate`)}</>}</Translation>
                                  </Button>
                                </Col>
                                <br />
                              </Row>
                            );
                          })}
                      </Col>

                      {userType !== "unit" && (
                        <>
                          <Col xl="3" lg="4" md="6" sm="12">
                            <Form.Group className="mb-3">
                              <label>
                                <Translation>{(t) => <>{t(`Employee / Member ID`)}</>}</Translation>
                                {!props.isSemiLogin && <span className="asterisk">*</span>}
                              </label>
                              <Translation>
                                {(t) => (
                                  <Form.Control
                                    placeholder={t(`Employee / Member ID`)}
                                    type="text"
                                    name="owner_reference"
                                    value={formData.owner_reference}
                                    onChange={handleChange}
                                    disabled={permission_id === 2 && Object.keys(unitPaymentSetting).length === 0}
                                    autoComplete="off"
                                    maxLength=""
                                    required
                                  ></Form.Control>
                                )}
                              </Translation>

                              {validations.owner_reference && <div className="help-block">{validations.owner_reference}</div>}
                            </Form.Group>
                          </Col>
                          <Col xl="2" lg="3" md="6" sm="12">
                            <Form.Group className="mb-3">
                              <label>
                                <Translation>{(t) => <>{t(`Access Card`)}</>}</Translation>
                              </label>
                              <Form.Control
                                placeholder="113123"
                                type="text"
                                name="access_card"
                                value={formData.access_card}
                                onChange={handleChange}
                                disabled={permission_id === 2 && Object.keys(unitPaymentSetting).length === 0}
                                autoComplete="off"
                                maxLength=""
                                required
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </>
                      )}
                    </Row>
                    {formData.permitOptionsList.filter((item) => item.permit_type === "monthly_permits").length > 0 && (
                      <>
                        {" "}
                        <Row>
                          <Col>
                            <b>
                              <Translation>{(t) => <>{t("Monthly")}</>}</Translation>
                            </b>
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col>
                            <div className="table-responsive ui-table mb-4" style={{ overflowX: "auto" }}>
                              <table>
                                <tr>
                                  <th style={{ width: "18%", minWidth: "80px" }}>
                                    <Translation>{(t) => <>{t("Issue")}</>}</Translation>
                                  </th>
                                  <th style={{ width: "18%", minWidth: "140px" }}>
                                    <Translation>{(t) => <>{t("Lot")}</>}</Translation>
                                  </th>
                                  <th style={{ width: "18%", minWidth: "140px" }}>
                                    <Translation>{(t) => <>{t("Permit Packages")}</>}</Translation>
                                  </th>
                                  <th style={{ width: "18%", minWidth: "140px" }}>
                                    <Translation>{(t) => <>{t("Description")}</>}</Translation>
                                  </th>
                                  <th style={{ width: "18%", minWidth: "140px" }}>
                                    <Translation>{(t) => <>{t("Type")}</>}</Translation>
                                  </th>
                                  <th style={{ width: "18%", minWidth: "140px" }}>
                                    <Translation>{(t) => <>{t("Stall Type")}</>}</Translation>
                                  </th>
                                  <th style={{ width: "18%", minWidth: "140px" }}>
                                    <Translation>{(t) => <>{t("Allowed to Issue")}</>}</Translation>
                                  </th>
                                  <th style={{ width: "18%", minWidth: "140px" }}>
                                    <Translation>{(t) => <>{t("Issued")}</>}</Translation>
                                  </th>
                                  {showHeader(permission_id, unitPaymentSetting)}
                                </tr>
                                {(formData.suite_id || suite_id) && formData.permitOptionsList.length
                                  ? formData.permitOptionsList.map((item, key) => {
                                      const permitPackages = (unitPaymentSetting?.lot_packages ?? []).filter(
                                        (lot) => lot.lot_id === item.lot_id && lot.package_name === item.package_name,
                                      )?.[0];

                                      return monthlyPermitPackages(item, key, permitPackages, unitPaymentSetting);
                                    })
                                  : null}
                              </table>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    {formData.permitOptionsList.filter((item) => item.permit_type === "usage_permits").length > 0 && (
                      <>
                        <Row>
                          <Col>
                            <b>Usage</b>
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col>
                            <div className="table-responsive ui-table mb-4" style={{ overflowX: "auto" }}>
                              <table>
                                <tr>
                                  <th style={{ width: "18%", minWidth: "80px" }}>
                                    <Translation>{(t) => <>{t("Issue")}</>}</Translation>
                                  </th>
                                  <th style={{ width: "18%", minWidth: "140px" }}>
                                    <Translation>{(t) => <>{t("Lot")}</>}</Translation>
                                  </th>
                                  <th style={{ width: "18%", minWidth: "140px" }}>
                                    <Translation>{(t) => <>{t("Permit Packages")}</>}</Translation>
                                  </th>
                                  <th style={{ width: "18%", minWidth: "140px" }}>
                                    <Translation>{(t) => <>{t("Description")}</>}</Translation>
                                  </th>
                                  <th style={{ width: "18%", minWidth: "140px" }}>
                                    <Translation>{(t) => <>{t("Type")}</>}</Translation>
                                  </th>
                                  <th style={{ width: "18%", minWidth: "140px" }}>
                                    <Translation>{(t) => <>{t("Stall Type")}</>}</Translation>
                                  </th>
                                  <th style={{ width: "18%", minWidth: "140px" }}>
                                    <Translation>{(t) => <>{t("Allowed to Issue")}</>}</Translation>
                                  </th>
                                  <th style={{ width: "18%", minWidth: "140px" }}>
                                    <Translation>{(t) => <>{t("Issued")}</>}</Translation>
                                  </th>

                                  {permission_id === 2 && unitPaymentSetting.payment_type === "free" ? null : (
                                    <>
                                      {" "}
                                      <th
                                        style={{
                                          width: "18%",
                                          minWidth: "140px",
                                        }}
                                      >
                                        <Translation>{(t) => <>{t("Expire In")}</>}</Translation>
                                      </th>
                                      <th
                                        style={{
                                          width: "18%",
                                          minWidth: "140px",
                                        }}
                                      >
                                        <Translation>{(t) => <>{t("Rate")}</>}</Translation>
                                      </th>
                                      <th
                                        style={{
                                          width: "18%",
                                          minWidth: "140px",
                                        }}
                                      >
                                        Usage
                                      </th>
                                    </>
                                  )}
                                </tr>
                                {(formData.suite_id || suite_id) && formData.permitOptionsList.length
                                  ? formData.permitOptionsList.map((item, key) => {
                                      const permitPackages = (unitPaymentSetting?.lot_packages ?? []).filter(
                                        (lot) => lot.lot_id === item.lot_id && lot.package_name === item.package_name,
                                      )?.[0];
                                      return usagePermitPackages(item, key, permitPackages, unitPaymentSetting);
                                    })
                                  : null}
                              </table>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    {validations.permit_option && <div className="help-block">{validations.permit_option}</div>}
                    <Row className="m-w-480px">
                      <Col className="col-auto">
                        <Form.Group className="mb-3">
                          <label>
                            <Translation>{(t) => <>{t("Country Code")}</>}</Translation>
                          </label>
                          <Form.Control
                            className="w-85px"
                            as="select"
                            name="country_code"
                            onChange={handleChange}
                            value={formData.country_code}
                            disabled={permission_id === 2 && Object.keys(unitPaymentSetting).length === 0}
                            required
                          >
                            {showCodeList(formData)}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="col p-0">
                        <Form.Group className="mb-3">
                          <label>
                            <Translation>{(t) => <>{t("Driver mobile number")}</>}</Translation>
                            <span className="asterisk">*</span>
                          </label>
                          <Translation>
                            {(t) => (
                              <Form.Control
                                placeholder={t("Contact Number")}
                                type="text"
                                name="contact_number"
                                value={formData.contact_number}
                                onChange={handleChange}
                                disabled={permission_id === 2 && Object.keys(unitPaymentSetting).length === 0}
                                autoComplete="off"
                                maxLength={10}
                                required
                              ></Form.Control>
                            )}
                          </Translation>

                          {validations.contact_number && <div className="help-block">{validations.contact_number}</div>}
                        </Form.Group>
                      </Col>
                      <Col className="col-auto">
                        <div>
                          <label>&nbsp;</label>
                        </div>
                        <Button
                          className="h-40px"
                          size="sm"
                          disabled={
                            formData.phone_options?.length > 9 ||
                            formData.contact_number?.trim()?.length === 0 ||
                            formData.contact_number?.trim()?.length > 10 ||
                            formData.contact_number?.trim()?.length < 10 ||
                            !isMultiplePhoneValid()
                          }
                          onClick={handleAddPhoneOptions}
                        >
                          <i className="fas fa-plus-circle"></i>
                        </Button>
                      </Col>
                    </Row>
                    {
                      multiplePhoneRender(formData.phone_options, formData.country_code_list)
                      // Multiple phone render
                    }
                    {validations.phone_number_options && <div className="help-block">{validations.phone_number_options}</div>}
                    <Row>
                      <Col xs={12} lg={12} className="col-auto">
                        {formData.unit_credit_card && userType === "unit" && unitPaymentSetting.payment_type === "unit_card" ? (
                          <div>
                            <div className="mt-2">
                              <Row>
                                <Col lg="6">
                                  <div className="form-group mb-3">
                                    <label>
                                      <Translation>{(t) => <>{t("Name")}</>}</Translation>
                                      <span>*</span>
                                    </label>
                                    <div className="d-flex align-items-center position-relative">
                                      <input
                                        required={true}
                                        type="text"
                                        className="form-control"
                                        name="credit_card_name"
                                        value={formData.credit_card_name}
                                        onChange={handleChange}
                                      />
                                      <div className="payment-formicon">
                                        <span className="fa fa-user" aria-hidden="true" />
                                      </div>
                                    </div>
                                    {validations.card_name && <div className="help-block">{validations.card_name}</div>}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="form-group mb-3">
                                    <label>
                                      <Translation>{(t) => <>{t("Email")}</>}</Translation>
                                      <span>*</span>
                                    </label>
                                    <div className="d-flex align-items-center position-relative">
                                      <input
                                        required={true}
                                        type="email"
                                        className="form-control"
                                        name="card_email"
                                        value={formData.card_email}
                                        onChange={handleChange}
                                      />
                                      <div className="payment-formicon">
                                        <span className="fa fa-envelope" aria-hidden="true" />
                                      </div>
                                    </div>
                                    {validations.card_email && <div className="help-block">{validations.card_email}</div>}
                                  </div>
                                </Col>
                              </Row>
                              <div className="form-group mb-3">
                                <label>
                                  <Translation>{(t) => <>{t("Credit Card number")}</>}</Translation>
                                  <span>*</span>
                                </label>
                                <div className="d-flex align-items-center position-relative">
                                  <input
                                    type="text"
                                    required={true}
                                    className="form-control"
                                    name="card_number"
                                    value={formData.card_number}
                                    onChange={handleChange}
                                  />
                                  <div className="payment-formicon">
                                    <span className="fa fa-credit-card" aria-hidden="true" />
                                  </div>
                                </div>
                                {validations.card_number && <div className="help-block">{validations.card_number}</div>}
                              </div>
                              <Row>
                                <Col lg="6">
                                  <div className="form-group mb-3">
                                    <label>
                                      <Translation>{(t) => <>{t("Expiration Date")}</>}</Translation>
                                      <span>*</span>
                                    </label>
                                    <div className="d-flex align-items-center position-relative">
                                      <DatePicker
                                        required
                                        placeholderText="MM/YY"
                                        // className="form-control datePickerPayment"
                                        name="card_expiry"
                                        selected={formData.card_expiry}
                                        onChange={handleCalender}
                                        dateFormat="MM/yy"
                                        showMonthYearPicker
                                        showFullMonthYearPicker
                                        minDate={new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
                                        customInput={
                                          <InputMask
                                            mask="99/99"
                                            maskChar={null}
                                            // placeholder="MM/YY"
                                            className="form-control datePickerPayment pe-5"
                                            name="card_expiry"
                                            value={formData?.card_expiry}
                                            onChange={handleCalender}
                                            showMask
                                          />
                                        }
                                      ></DatePicker>
                                      <div className="payment-formicon">
                                        <span
                                          className="fa fa-calendar"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          aria-hidden="true"
                                        />
                                      </div>
                                    </div>
                                    {validations.card_expiry && <div className="help-block">{validations.card_expiry}</div>}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="form-group mb-3">
                                    <label>
                                      CVV
                                      <span>*</span>
                                    </label>
                                    <div className="d-flex align-items-center position-relative">
                                      <input
                                        maxLength={4}
                                        required={true}
                                        type="password"
                                        className="form-control"
                                        name="card_cvv"
                                        value={formData.card_cvv}
                                        onChange={handleChange}
                                      />
                                      <div className="payment-formicon">
                                        <span className="fa fa-key" aria-hidden="true" />
                                      </div>
                                    </div>
                                    {validations.card_cvv && <div className="help-block">{validations.card_cvv}</div>}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        ) : (
                          userType === "unit" &&
                          unitPaymentSetting.payment_type === "unit_card" && (
                            <div className="ml-1 mr-1">
                              <div className="mb-2 mt-3 change-credit-card-box">
                                <div className="mt-2">
                                  <Row>
                                    <Col lg="6">
                                      <div className="form-group mb-3">
                                        <label>
                                          <Translation>{(t) => <>{t("Card Holder Name")}</>}</Translation>
                                          <span class="asterisk">*</span>
                                        </label>
                                        <div className="d-flex align-items-center position-relative">
                                          <input
                                            required={true}
                                            type="text"
                                            className="form-control pe-5"
                                            name="credit_card_name"
                                            value={formData.credit_card_name}
                                            onChange={handleChange}
                                            disabled={formData.disable_card_options}
                                          />
                                          <div className="payment-formicon">
                                            <span className="fa fa-user" aria-hidden="true" />
                                          </div>
                                        </div>
                                        {validations.card_name && <div className="help-block">{validations.card_name}</div>}{" "}
                                      </div>
                                    </Col>
                                    <Col lg="6">
                                      <div className="form-group mb-3">
                                        <label>
                                          <Translation>{(t) => <>{t("Email")}</>}</Translation>
                                          <span>*</span>
                                        </label>
                                        <div className="d-flex align-items-center position-relative">
                                          <input
                                            required={true}
                                            type="email"
                                            className="form-control"
                                            name="card_email"
                                            value={formData.card_email}
                                            onChange={handleChange}
                                            disabled={formData.disable_card_options}
                                          />
                                          <div className="payment-formicon">
                                            <span className="fa fa-envelope" aria-hidden="true" />
                                          </div>
                                        </div>
                                        {validations.card_email && <div className="help-block">{validations.card_email}</div>}
                                      </div>
                                    </Col>
                                  </Row>

                                  <div className="form-group mb-3">
                                    <label>
                                      <Translation>{(t) => <>{t("Credit Card number")}</>}</Translation>
                                      <span class="asterisk">*</span>
                                    </label>
                                    <div className="d-flex align-items-center position-relative">
                                      <input
                                        type="text"
                                        required={true}
                                        className="form-control pe-5"
                                        name="card_number"
                                        value={formData.card_number}
                                        onChange={handleChange}
                                        maxLength={16}
                                        disabled={formData.disable_card_options}
                                      />
                                      <div className="payment-formicon">
                                        <span className="fa fa-credit-card" aria-hidden="true" />
                                      </div>
                                    </div>
                                    {validations.card_number && <div className="help-block">{validations.card_number}</div>}{" "}
                                  </div>

                                  <div className="form-group mb-4">
                                    <Row>
                                      <Col lg="12">
                                        <div className="form-group mb-3">
                                          <label>
                                            <Translation>{(t) => <>{t("Expiration Date")}</>}</Translation>
                                            <span class="asterisk">*</span>
                                          </label>
                                          <div className="d-flex align-items-center position-relative">
                                            <input
                                              type="text"
                                              required={true}
                                              className="form-control"
                                              name="card_expiry"
                                              value={formData.card_expiry}
                                              onChange={handleChange}
                                              disabled={formData.disable_card_options}
                                            />
                                            <div className="payment-formicon">
                                              <span
                                                className="fa fa-calendar"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                aria-hidden="true"
                                              />
                                            </div>
                                          </div>
                                          {validations.card_expiry && <div className="help-block">{validations.card_expiry}</div>}{" "}
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                                <div class="d-flex justify-content-end gap-4">
                                  <div className="my-2 mr-3">
                                    <Button className="btn-fill btn-sm mb-2" type="button" variant="info" onClick={() => editCardDetails()}>
                                      <span className="pr-2">
                                        <i class="far fa-edit"></i>
                                      </span>
                                      <Translation>{(t) => <>{t("Edit Card")}</>}</Translation>
                                    </Button>
                                  </div>
                                  <div className="my-2">
                                    <Button className="btn-fill btn-sm mb-2" type="button" variant="danger" onClick={() => deleteCardDetails()}>
                                      <span className="pr-2">
                                        <i class="far fa-trash-alt"></i>
                                      </span>
                                      <Translation>{(t) => <>{t("Delete Card")}</>}</Translation>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </Col>
                    </Row>

                    <Row>
                      {stallType === "user_selected" ? (
                        <Col xl="4" lg="3" md="6" sm="12">
                          <Form.Group controlId="exampleForm.ControlSelect1" className="mb-3">
                            <Form.Label className="mb-0">
                              <Translation>{(t) => <>{t("Select Stall")}</>}</Translation>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="stall_id"
                              value={formData.stall_id}
                              onChange={handleChange}
                              placeholder="Select Stall"
                              disabled={!allStallData?.length}
                            >
                              <Translation>{(t) => <option value={""}>--{t("Select Stall")}--</option>}</Translation>
                              {allStallData?.length ? (
                                <>
                                  {(allStallData ?? []).map((item) => (
                                    <option key={item.id} id={item.id} value={item.stall_id}>
                                      {item.stall_name}
                                    </option>
                                  ))}
                                </>
                              ) : null}
                            </Form.Control>
                            {validations.stall_id && <div className="help-block">{validations.stall_id}</div>}
                            <label className="mt-2">{!allStallData?.length && "Note: There are no stall available for this lot"}</label>
                          </Form.Group>
                        </Col>
                      ) : (
                        stallType === "no_stall" && (
                          <Col xl="2" lg="3" md="8" sm="12">
                            <Form.Group className="mb-3">
                              <label>
                                <Translation>{(t) => <>{t("Driver Stall Number")}</>}</Translation>
                              </label>
                              <Form.Control
                                placeholder="P1-10"
                                type="text"
                                name="driver_stall_id"
                                value={formData.stall_id}
                                onChange={handleChange}
                                disabled={Object.keys(unitPaymentSetting).length === 0}
                                autoComplete="off"
                                maxLength=""
                                required
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        )
                      )}
                      {userType !== "unit" && (
                        <>
                          {!props.isSemiLogin && unitPaymentSetting?.allow_permit_share ? (
                            <>
                              <Col lg={3}>
                                <Form.Group className="mb-3">
                                  <label>
                                    <Translation>{(t) => <>{t("Select Permit Group")}</>}</Translation>
                                  </label>
                                  <Form.Control
                                    as="select"
                                    disabled={groupButton}
                                    name="permit_group"
                                    onChange={(e) => handleSelectedGroup(e)}
                                    value={formData.selectedPermitGroupId}
                                  >
                                    <Translation>{(t) => <option value={""}>{t("Select Permit Share Group")}</option>}</Translation>
                                    {showPermitGroupList(permitGroupList)}
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                              <Col className="col-auto mb-3">
                                {" "}
                                <Button
                                  type="button"
                                  className="btn-fill mt-4"
                                  variant="primary"
                                  size="small"
                                  disabled={groupButton}
                                  onClick={openClosePermitsGroupModal}
                                >
                                  <Translation>{(t) => <>{t("Add Permit Share")}</>}</Translation>
                                </Button>
                              </Col>
                            </>
                          ) : (
                            ""
                          )}
                          {!startDateDisable && (
                            <Col xl="4" lg="3">
                              <Form.Group className="mb-3">
                                <label>
                                  <Translation>{(t) => <>{t("Start Date")}</>}</Translation>
                                </label>
                                <DatePicker
                                  className="form-control"
                                  placeholderText="Select Date"
                                  selected={formData.startDate}
                                  value={formData.startDate}
                                  selectsStart
                                  disabled={startDateDisable}
                                  name="startDate"
                                  //  timeInputLabel="Time:"
                                  onChange={handleDateChange}
                                  dateFormat="MM/dd/yyyy"
                                  minDate={new Date()}
                                  maxDate={new Date().setDate(new Date().getDate() + 9)}
                                  autoComplete="off"
                                />
                              </Form.Group>
                            </Col>
                          )}
                        </>
                      )}
                    </Row>
                    <Row>
                      {!formData.autoRenewHide && (
                        <Col md="3" lg="2" className="mt-3">
                          <label className="fancy-checkbox mt-0 mb-3">
                            <input
                              type="checkbox"
                              name="is_auto_renew"
                              className="custom-control-input"
                              id="is_auto_renew"
                              onChange={handleChange}
                              // disabled={(permission_id === 2 && Object.keys(unitPaymentSetting).length === 0) || startDateDisable}
                              checked={formData.is_auto_renew}
                            ></input>
                            <span>
                              <i></i> <Translation>{(t) => <>{t("Auto Renew")}</>}</Translation>
                            </span>
                          </label>
                        </Col>
                      )}

                      <Col md="3" lg="2" className="mt-3">
                        <label className="fancy-checkbox mt-0 mb-3">
                          <input
                            type="checkbox"
                            name="is_send_mail"
                            className="custom-control-input"
                            id="is_send_mail"
                            onChange={handleChange}
                            checked={formData.is_send_mail}
                          ></input>
                          <span>
                            <i></i> <Translation>{(t) => <>{t("Send Mail")}</>}</Translation>
                          </span>
                        </label>
                      </Col>
                      <Col md="3" lg="2"></Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <div className="visitorPermit-controls mt-3 d-flex justify-content-end">
                          <Button
                            type="button"
                            className="btn-fill"
                            variant="primary"
                            disabled={(permission_id === 2 && Object.keys(unitPaymentSetting).length === 0) || formData.isFormValid}
                            // disabled={!this.state.isFormValid}
                            onClick={handleSubmit}
                          >
                            <Translation>{(t) => <>{t("Submit")}</>}</Translation>
                          </Button>
                          {(userType === "superadmin" || userType === "admin") && (
                            <Button
                              type="button"
                              className="btn-fill ml-3"
                              variant="primary"
                              // disabled={loader}
                              // disabled={!this.state.isFormValid}
                              onClick={openPermitsImportModal}
                            >
                              Bulk Import
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {formData.showPermitsImportModal && (
        <BulkImportModal
          open={formData.showPermitsImportModal}
          type={"PaidPermit"}
          UploadFile={handleFileUpload}
          injectedTableData={responseTableData}
          modelLoader={modelLoader}
          closeModal={openPermitsImportModal}
        />
      )}
      {formData.showPermitsGroupModal && (
        <PermitShareGroupModal
          closeModal={openClosePermitsGroupModal}
          lotPackage={lotPackage}
          property_id={property_id}
          residentList={residentList}
          formData={formData}
        />
      )}
      {showDeleteCardModal(formData)}
      {alert && (api_success_message || api_error_message) && (
        <Translation>
          {(t) => (
            <CustomContentAlert
              delay={6000}
              message={t(api_success_message || api_error_message)}
              className={api_success_message ? "toast-success" : "toast-error"}
            />
          )}
        </Translation>
      )}
    </div>
  );
};

export default UnitCreatePermits;

import t from "../../Actions/EnforcementActions/types";

const intitialState = {
  plateCheckData: {},
  extractedPlateNumber: "",
  userlots: [],
  usertypes: [],
  allUsers: [],
  auditReportList: [],
  citationList: [],
  loader: false,
  citationSummaryList: [],
  citationPaidList: [],
  revenueList: [],
  revenueSummaryList: [],
  GetOfflineReasonData: [],
  GetOfflinePermitData: [],
  user_offline: {},
  cloud_to_offline_sync: null,
  offline_to_cloud_sync: null
};

const EnforcementReducer = (state = intitialState, action) => {
  switch (action.type) {
    case t.FETCH_PALTE_VALIDITY: {
      return {
        ...state,
        plateCheckData: action.payload,
      };
    }
    case t.FETCH_PALTE_SCANNED_DATA: {
      const scannedPlateResponseData = action.payload;

      const results = Object.keys(scannedPlateResponseData) && Object.keys(scannedPlateResponseData).length && scannedPlateResponseData.results;

      return {
        ...state,
        extractedPlateNumber: results[0].plate,
      };
    }
    case t.GETUSERLOTS: {
      return { ...state, userlots: action.payload };
    }
    case t.GETUSERTYPES: {
      return { ...state, usertypes: action.payload };
    }
    case t.GET_AUDIT_REPORT: {
      return { ...state, auditReportList: action.payload };
    }
    case t.GETALLUSERS: {
      return { ...state, allUsers: action.payload };
    }
    case t.GET_OFFLINE_USER: {
      return { ...state, user_offline: action.payload };
    }
    case t.SHOW_HIDE_LOADER: {
      return { ...state, loader: action.payload };
    }
    case t.FETCH_CITATION_REPORT_LIST: {
      return { ...state, citationList: action.payload };
    }
    case t.FETCH_CITATION_ISSUED_SUMMARY: {
      return { ...state, citationSummaryList: action.payload };
    }
    case t.FETCH_CITATION_PAID_SUMMARY: {
      return { ...state, citationPaidList: action.payload };
    }
    case t.FETCH_REVENUE_REPORT_LIST: {
      return { ...state, revenueList: action.payload };
    }
    case t.FETCH_REVENUE_REPORT_SUMMARY_LIST: {
      return { ...state, revenueSummaryList: action.payload };
    }
    case t.GET_CLOUD_TO_OFFLINE_SYNC: {
      return { ...state, cloud_to_offline_sync: action.payload };
    }
    case t.GET_OFFLINE_TO_CLOUD_SYNC: {
      return { ...state, offline_to_cloud_sync: action.payload };
    }
    case t.GETOFFLINEDATA: {
      return {
        ...state,
        GetOfflinePermitData: action.payload.permits,
        GetOfflineReasonData: action.payload.reasons,
        GraceTime: action.payload.grace_time,
        VinVisibility: action.payload.vin_visibility,
        TicketDefaultLanguage: action.payload.ticket_default_language,
        GetOfflineAuditData: action.payload.audit_data,
      };
    }
    case t.CREATE_OR_UPDATE_OFFLINE_SESSIONS: {
      return {
        ...state,
        GetOfflineUserData: action.payload,
      };
    }

    default:
      return state;
  }
};

export default EnforcementReducer;

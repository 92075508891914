import t from "./types";
import apiType from "../APIResponseActions/type";
import { AuthAPI, BufferAuthAPI } from "../../../Auth/requestHandler";
import moment from "moment";
import Config from "../../../Auth/config";
import { convertPropertyTimeToUtc } from "../../Components/helper";

export const fetchCurrentPermits = (pageobj) => async (dispatch) => {
  try {
    let url = "";
    const unix_time = Math.round(new Date().getTime() / 1000);
    const currentDate = unix_time - 14400;
    const exportPemrits = !!pageobj?.exportLink;
    url =
      "permit?permit_type=" +
      pageobj.permitType +
      "&status=" +
      pageobj.status +
      "&suite_id=" +
      pageobj.suite_id +
      "&page_size=" +
      pageobj.Norow +
      "&page=" +
      pageobj.page +
      "&search=" +
      pageobj.searchValue +
      "&order_by=" +
      pageobj.selectedColumn +
      "&sorting=" +
      pageobj.sort_direction +
      "&current_time=" +
      currentDate +
      "&exportLink=" +
      exportPemrits +
      "&childcheckbox=" +
      pageobj.childcheckbox +
      "&propertyId=" +
      pageobj.PropertyId +
      "&unit_language=" +
      pageobj.unit_language;

    await dispatch({ type: apiType.API_RESPONSE_SUCCESS, payload: "" });

    if (exportPemrits) {
      try {
        return await BufferAuthAPI("domain", url, "GET");
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const data = await AuthAPI("domain", url, "GET");
      await dispatch({ type: t.FETCH_CURRENT_PERMIT, payload: data });
      await dispatch({ type: apiType.API_RESPONSE_SUCCESS, payload: "" });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const fetchPermitsPackages = (pageobj) => async (dispatch) => {
  try {
    let url = "";
    const unix_time = Math.round(new Date().getTime() / 1000);
    const currentDate = unix_time - 14400;
    const exportPemrits = !!pageobj?.exportLink;
    url =
      "permit?permit_type=PermitPackage" +
      "&status=" +
      pageobj.status +
      "&suite_id=" +
      pageobj.suite_id +
      "&lot_id=" +
      pageobj.lot_id +
      "&package_name=" +
      pageobj.package_name +
      "&auto_renew=" +
      pageobj.auto_renew +
      "&cron_auto_renew=" +
      pageobj.cron_auto_renew +
      "&primary_permit=" +
      pageobj.primary_permit +
      "&page_size=" +
      pageobj.Norow +
      "&page=" +
      pageobj.page +
      "&search=" +
      pageobj.searchValue +
      "&order_by=" +
      pageobj.selectedColumn +
      "&sorting=" +
      pageobj.sort_direction +
      "&current_time=" +
      currentDate +
      "&exportLink=" +
      exportPemrits +
      "&childcheckbox=" +
      pageobj.childcheckbox +
      "&propertyId=" +
      pageobj.PropertyId;
    if (exportPemrits) {
      try {
        return await BufferAuthAPI("domain", url, "GET");
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const data = await AuthAPI("domain", url, "GET");
      await dispatch({ type: t.FETCH_PERMIT_PACKAGES, payload: data });
      await dispatch({ type: apiType.API_RESPONSE_SUCCESS, payload: "" });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const updatePermit = (permitId, body, PageObject) => async (dispatch) => {
  await dispatch({
    type: apiType.API_RESPONSE_FAILURE,
    payload: "",
  });
  await dispatch({
    type: t.UPDATE_PERMIT_ERROR,
    payload: "",
  });
  try {
    const resource = "permit/" + permitId;
    body.id = permitId;

    const response = await AuthAPI("domain", resource, "PATCH", body);
    await dispatch({ type: t.UPDATE_PERMIT, payload: body });
    if (response.success === 1) {
      dispatch({
        type: t.UPDATE_PERMIT_SUCCESS,
        payload: response.message,
      });
      await dispatch({
        type: t.FETCH_CURRENT_PERMIT,
        payload: response.data,
      });
      dispatch(fetchPermitsPackages(PageObject));
    } else {
      await dispatch({
        type: t.UPDATE_PERMIT_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const AddPermits =
  (bookingSlots, confirm_data, userDetails, permit_credit_group, credit_type, timezone, card_details = {}) =>
  async (dispatch) => {
    try {
      console.log("permit_credit_group,userDetails,bookingSlots::::", permit_credit_group, userDetails, bookingSlots);

      const dateFormateWithTime = "YYYY/MM/DD HH:mm";
      const start_reservation_time = moment(bookingSlots[0].startDate).utcOffset(timezone).format(dateFormateWithTime);
      const end_reservation_time = moment(bookingSlots[bookingSlots.length - 1].endDate)
        .utcOffset(timezone)
        .format(dateFormateWithTime);
      console.log("start_reservation_time=========", start_reservation_time);

      const { startDateInUtc: UTCBT, endDateInUtc: UTCET } = convertPropertyTimeToUtc(timezone, start_reservation_time, end_reservation_time, true);

      const total_credit = permit_credit_group.reduce((acc, el) => {
        return acc + parseFloat(el.credits);
      }, 0);
      let dataToSend = {};
      const data_permit = [];
      if (confirm_data.multiplePermits) {
        bookingSlots.forEach((element) => {
          const { startDateInUtc: UTCBT, endDateInUtc: UTCET } = convertPropertyTimeToUtc(timezone, element.startDate, element.endDate, true);
          data_permit.push({
            creator_uid: userDetails?.uid,
            company_id: userDetails?.company_id ? userDetails?.company_id : "",
            property_id: userDetails?.property_id,
            lot_id: userDetails?.LotVisitorsId,
            begin_time: UTCBT, // moment(element.startDate)
            expire_time: UTCET, // moment(element.endDate)
            role: "visitor",
            permit_class: "Visitor",
            cost: element.credits, // changed this from bookingSlots.length to total_credit
            email: userDetails.email,
            suite_id: userDetails.suite_id,
            lpn_restriction: element.lpn_restriction,
            timeslot_name: element.alias || element.timeslot_name,
            ...(confirm_data && {
              first_name: confirm_data.firstName ? confirm_data.firstName : "",
              last_name: confirm_data.lastName ? confirm_data.lastName : "",
              country_code: confirm_data.country_code ? confirm_data.country_code : "+1",
              phone: confirm_data.phone ? confirm_data.phone : "",
              phone_options: confirm_data.phone_options ? confirm_data.phone_options : [],
              lpn: confirm_data.plateNumber ? confirm_data.plateNumber : "",
              car_make: confirm_data.vehicle_make ? confirm_data.vehicle_make : "",
              car_model: confirm_data.vehicle_model ? confirm_data.vehicle_model : "",
              car_color: confirm_data.vehicle_color ? confirm_data.vehicle_color : "",
            }),
            ...(card_details && {
              ...card_details,
            }),
            ...{
              group_name: element.group_name,
              credits: element.credits,
            },
          });
        });
        if (credit_type === "single") {
          dataToSend = {
            credit_type: credit_type,
            permit: data_permit,
            multiple_permits: confirm_data.multiplePermits,
            timeslot_group: [],
            credit_used: parseFloat(confirm_data.usedCredits),
            free_credit: confirm_data.leftCredit >= 0 ? confirm_data.leftCredit : 0,
            unit_language: confirm_data.unit_language,
          };
        } else if (credit_type === "multiple") {
          dataToSend = {
            credit_type: credit_type,
            permit: data_permit,
            permit_credit_group: [],
            timeslot_group: [],
            multiple_permits: confirm_data.multiplePermits,
            credit_used: parseFloat(confirm_data.usedCredits),
            free_credit: confirm_data.leftCredit >= 0 ? confirm_data.leftCredit : 0,
            unit_language: confirm_data.unit_language,
          };
        }
      } else {
        const data_permit = [
          {
            creator_uid: userDetails.uid,
            company_id: userDetails.CompanyId,
            property_id: userDetails.property_id,
            lot_id: userDetails?.LotVisitorsId,
            begin_time: UTCBT, // start_reservation_time,
            expire_time: UTCET, // end_reservation_time,
            role: "visitor",
            permit_class: "Visitor",
            cost: total_credit, // changed this from bookingSlots.length to total_credit
            email: userDetails.email,
            suite_id: userDetails.suite_id,
            ...(confirm_data && {
              first_name: confirm_data.firstName ? confirm_data.firstName : "",
              last_name: confirm_data.lastName ? confirm_data.lastName : "",
              country_code: confirm_data.country_code ? confirm_data.country_code : "+1",
              phone: confirm_data.phone ? confirm_data.phone : "",
              phone_options: confirm_data.phone_options ? confirm_data.phone_options : [],
              lpn: confirm_data.plateNumber ? confirm_data.plateNumber : "",
              car_make: confirm_data.vehicle_make ? confirm_data.vehicle_make : "",
              car_model: confirm_data.vehicle_model ? confirm_data.vehicle_model : "",
              car_color: confirm_data.vehicle_color ? confirm_data.vehicle_color : "",
            }),
            ...(card_details && {
              ...card_details,
            }),
          },
        ];
        if (credit_type === "single") {
          dataToSend = {
            credit_type: credit_type,
            permit: data_permit,
            timeslot_group: permit_credit_group,
            multiple_permits: confirm_data.multiplePermits,
            credit_used: parseFloat(confirm_data.usedCredits),
            free_credit: confirm_data.leftCredit >= 0 ? confirm_data.leftCredit : 0,
            unit_language: confirm_data.unit_language,
          };
        } else if (credit_type === "multiple") {
          dataToSend = {
            credit_type: credit_type,
            permit: data_permit,
            permit_credit_group: permit_credit_group,
            timeslot_group: permit_credit_group,
            multiple_permits: confirm_data.multiplePermits,
            credit_used: parseFloat(confirm_data.usedCredits),
            free_credit: confirm_data.leftCredit >= 0 ? confirm_data.leftCredit : 0,
            unit_language: confirm_data.unit_language,
          };
        }
      }

      const response3 = await AuthAPI("domain", "permit", "POST", dataToSend);
      if (response3?.success) {
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: response3.msg,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response3?.message,
        });
      }
    } catch (error) {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: error.toString(),
      });
    }
  };

export const getTimeSlot = (suite_id) => async (dispatch) => {
  try {
    let unix_time = Math.round(new Date().getTime() / 1000);
    unix_time = unix_time - 14400;
    const data = await AuthAPI("domain", "timeslot?current_date=" + unix_time + "&suite_id=" + suite_id, "GET");
    await dispatch({ type: t.FETCH_TIME_SLOT, payload: data });
    await dispatch({ type: apiType.API_RESPONSE_SUCCESS, payload: "" });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};
export const InitializePermitAllowedType = () => async (dispatch) => {
  try {
    await dispatch({
      type: t.FETCH_PERMIT_ALLOWED_TYPE,
      payload: 1,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const fetchResidentList = (property_id) => async (dispatch) => {
  try {
    const response = await AuthAPI("domain", "user/residents/of-property?property_id=" + property_id, "GET");
    await dispatch({ type: t.FETCH_RESIDENT_LIST, payload: response.data });
    await dispatch({
      type: t.FETCH_TODAYS_DATE,
      payload: response.todays_date,
    });
    await dispatch({ type: apiType.API_RESPONSE_SUCCESS, payload: "" });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const selectedUnitLanguage = (lang) => async (dispatch) => {
  try {
    await dispatch({
      type: t.UNIT_SELECT_LANG,
      payload: lang,
    });
  } catch (error) {
    console.log(error);
    return "";
  }
};

export const getCreditsIndividualDetails = (suite_id, propertyId) => async (dispatch) => {
  try {
    let unix_time = Math.round(new Date().getTime() / 1000);
    unix_time = unix_time - 14400;
    const resource = "permit/" + propertyId + "/" + suite_id + "/" + unix_time;
    const response = await AuthAPI("domain", resource, "GET");

    await dispatch({
      type: t.FETCH_CREDITS_ALLOCATED,
      payload: response.credits_individual_detail,
    });
    await dispatch({
      type: t.FETCH_SINGLE_CREDIT_AVAILABILITY,
      payload: response.single_credit_availability,
    });
    await dispatch({
      type: t.FETCH_CREDITS_USED_30DAYS,
      payload: response.credit_used_30days,
    });
    await dispatch({
      type: t.FETCH_CREDITS_30DAYS_REEPORTS,
      payload: response.credit_30days_reports,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getCurrentPermits = (propertyId, availableSpots, credit, suite_id) => async (dispatch) => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateFormateWithTime = "YYYY/MM/DD HH:mm";
    let unix_time = Math.round(new Date().getTime() / 1000);
    unix_time = unix_time - 14400;
    const resource = "permit/" + propertyId + "/" + suite_id + "/" + unix_time;
    const response = await AuthAPI("domain", resource, "GET");
    const timeslotData = await AuthAPI("domain", "timeslot?current_date=" + unix_time + "&suite_id=" + suite_id, "GET");

    let data_parked = response.current_permits;
    data_parked = data_parked.filter((item) => {
      return item.permit_class === "Visitor";
    });
    // (allValues.fields ?? [])
    //   .map()
    (data_parked ?? []).map((item) => {
      item.BeginTime = moment(item.BeginTime).utcOffset(timezone).format(dateFormateWithTime);
      item.ExpiryTime = moment(item.ExpiryTime).utcOffset(timezone).format(dateFormateWithTime);
      return item;
    });
    const timeSlot = timeslotData.time_slot;
    const dateSlot = timeslotData.date_slot;
    const available_spot = timeslotData.available_spot;
    await dispatch({
      type: t.SET_AVAILABLESPOTS,
      payload: timeslotData.available_spot,
    });
    await dispatch({
      type: t.FETCH_DATE_LIST,
      payload: { data_parked, credit, timeSlot, dateSlot, available_spot },
    });
    await dispatch({
      type: t.FETCH_RESIDENTSCREDITSARR,
      payload: response.credits,
    });
    await dispatch({
      type: t.FETCH_PERMIT_ALLOWED_TYPE,
      payload: timeslotData.permits_allowed_type,
    });
    await dispatch({ type: t.FETCH_TIME_SLOT, payload: timeslotData });
    await dispatch({
      type: t.FETCH_RESIDENTSPASTCREDITSARR,
      payload: response.past_credits,
    });
    await dispatch({
      type: t.FETCH_USED_CREDITS,
      payload: response.past_credit_individual_detail,
    });
    await dispatch({
      type: t.FETCH_CURRENT_CREDITS,
      payload: response.credits_individual,
    });
    await dispatch({
      type: t.FETCH_CREDITS_ALLOCATED,
      payload: response.credits_individual_detail,
    });
    await dispatch({
      type: t.FETCH_CREDITS_30DAYS_REEPORTS,
      payload: response.credit_30days_reports,
    });
    await dispatch({
      type: t.FETCH_CREDITS_USED_30DAYS,
      payload: response.credit_used_30days,
    });
    await dispatch({
      type: t.FETCH_PAST_CREDITS_ALLOCATED,
      payload: response.past_credits_individual,
    });
    await dispatch({
      type: t.FETCH_SINGLE_CREDIT_AVAILABILITY,
      payload: response.single_credit_availability,
    });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: response.msg,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getSlots = (propertyId, permit_id) => async (dispatch) => {
  try {
    let unix_time = Math.round(new Date().getTime() / 1000);
    unix_time = unix_time - 14400;
    const resource = "permit/get-slots/" + propertyId + "/" + permit_id + "/" + unix_time;
    const response = await AuthAPI("domain", resource, "GET");
    await dispatch({
      type: t.FETCH_SLOTS,
      payload: response.credit_30days_reports,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const deActivatePermit = (permitId) => async (dispatch) => {
  try {
    const resource = "permit/" + permitId + "/deactivate";
    const response = await AuthAPI("domain", resource, "PATCH");
    await dispatch({ type: t.UPDATE_CURRENT_PERMIT, payload: permitId });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: response.message,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const visitorPermitDelete = (permitId) => async (dispatch) => {
  try {
    const resource = "permit/" + permitId + "/visitordeactivate";
    const response = await AuthAPI("domain", resource, "PATCH");
    await dispatch({ type: t.DEACTIVATE_VISITOR, payload: permitId });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: response.message,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const pdfSendToMail = (sendMailData) => async (dispatch) => {
  try {
    const url = "permit_options/send-receipt-mail";
    const body = sendMailData;
    const response = await AuthAPI("domain", url, "POST", body);
    console.log("Mail send Response success::::::", response);
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: response.message,
    });
  } catch (error) {
    console.log(error);
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.message,
    });
  }
};

export const getParkingReceiptPDF = (row) => async (dispatch) => {
  try {
    const url = "get_parking_pdf/" + row.property_id + "/" + row.id + "/" + row?.language;
    return await new Promise((resolve) => {
      fetch(`${Config.baseURL}/${url}`)
        .then((blobData) => blobData.blob())
        .then((response) => {
          console.log("==== response", response);
          resolve(URL.createObjectURL(response));
        })
        .catch((error) => {
          console.log("==== error", error);
        });
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const call_when_here_activate_deactive = (permitId) => async (dispatch) => {
  try {
    const url = "permit/call_when_here/" + permitId;
    const response = await AuthAPI("domain", url, "GET");
    await dispatch({
      type: t.CALL_WHEN_HERE_ACTIVATE_DEACTIVATE,
      payload: permitId,
    });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: response.msg,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const activateDeactivateAutoRenewForPermit = (row) => async (dispatch) => {
  try {
    const url = "permit/update-auto-renew-status";
    const response = await AuthAPI("domain", url, "POST", row);
    console.log("responseresponse", response);
    if (response?.success) {
      await dispatch({
        type: t.AUTO_RENEW_ACTIVATE_DEACTIVATE,
        payload: row?.id,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const activateDeactivateAutoCancelForPermit = (row) => async (dispatch) => {
  await dispatch({
    type: apiType.API_RESPONSE_FAILURE,
    payload: "",
  });
  await dispatch({
    type: apiType.API_RESPONSE_SUCCESS,
    payload: "",
  });
  try {
    const url = "permit/update-auto-cancel-status";
    const response = await AuthAPI("domain", url, "POST", row);
    if (response?.success) {
      await dispatch({
        type: t.AUTO_CANCEL_ACTIVATE_DEACTIVATE,
        payload: row.id,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const cancelAndRefundPaidPermit =
  (permitId, based_on_days, amount = 0) =>
  async (dispatch) => {
    try {
      const url = `permit/cancel-and-refund-permit/${permitId}/${based_on_days}/${amount}`;
      const response = await AuthAPI("domain", url, "GET");
      if (response.success === 1 || response.success === 2) {
        await dispatch({
          type: t.UPDATE_CURRENT_PERMIT,
          payload: permitId,
        });
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: response.message,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    } catch (error) {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: error.toString(),
      });
    }
  };

export const setResidentCredits = (config) => async (dispatch) => {
  await dispatch({ type: t.FETCH_CREDITS, payload: config });
};

export const getResidentPermits = (suite_id, propertyId) => async (dispatch) => {
  try {
    const resource = "permit/resident/" + suite_id + "/" + propertyId;
    const response = await AuthAPI("domain", resource, "GET");
    await dispatch({ type: t.FETCH_RESIDENTS_PERMIT, payload: response });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

// export const visitorPermitDelete = (id) => async (dispatch) => {
//  try {
//    const url = "permit/" + id;
//    const response = await AuthAPI("domain", url, "DELETE");
//    if (response && response.success) {
//      await dispatch({ type: t.DELETE_VISITOR, payload: id });
//      await dispatch({
//        type: apiType.API_RESPONSE_SUCCESS,
//        payload: response.message,
//      });
//    }
//  } catch (error) {
//    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
//  }
// };

export const addSharingLots = (data) => async (dispatch) => {
  try {
    const url = "lot/add-lot-sharing";
    const response = await AuthAPI("domain", url, "POST", data);
    if (response?.success) {
      await dispatch({ type: t.GET_SHARED_LOT, payload: response.data });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const get_shared_lots = (propertyId) => async (dispatch) => {
  try {
    const url = "lot/get-all-shared-lot?property_id=" + propertyId;
    const response = await AuthAPI("domain", url, "GET");
    if (response) {
      await dispatch({ type: t.GET_SHARED_LOT, payload: response });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const deleteSharingLots = (property_id, id) => async (dispatch) => {
  try {
    const url = "lot/delete-lot-sharing?property_id=" + property_id + "&id=" + id;
    const response = await AuthAPI("domain", url, "DELETE");
    if (response?.success) {
      await dispatch({ type: t.GET_SHARED_LOT, payload: response.data });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const GetGuestData = (data) => async (dispatch) => {
  try {
    const url = "permit/get-guest";
    const response = await AuthAPI("domain", url, "POST", data);
    if (response?.success) {
      await dispatch({ type: t.GET_GUEST, payload: response.data });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};
